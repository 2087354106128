import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useState, useEffect, useLayoutEffect } from 'react';

interface QuestionFileNameError {
  status: boolean;
  message: string;
}

interface QuestionFileNameProps {
  error?: QuestionFileNameError;
  onValueChange: (value: string) => void;
  questionFileName?: string;
}

const QuestionFileName: React.FunctionComponent<QuestionFileNameProps> = ({
  onValueChange,
  error,
  questionFileName,
}) => {
  const [errorStatus, setErrorStatus] = useState(error?.status);
  const [value, setValue] = useState('');
  const [isReadOnly, setIsReadOnly] = useState(false);

  const handleChange = (changedValue) => {
    setValue(changedValue);
    onValueChange(changedValue.trim());
  };

  useLayoutEffect(() => {
    const handleClickChangeType = () => {
      handleChange('');
    };
    const changeQuestionTypeButton = document.getElementsByClassName(
      'lrn-qe-btn lrn-qe-btn-change lrn-qe-margin-right-sm'
    )[0] as HTMLButtonElement;
    if (!questionFileName && changeQuestionTypeButton) {
      changeQuestionTypeButton.addEventListener('click', handleClickChangeType);
    }
    return () => {
      if (changeQuestionTypeButton) {
        changeQuestionTypeButton.removeEventListener(
          'click',
          handleClickChangeType
        );
      }
    };
  }, []);

  useEffect(() => {
    setErrorStatus(error?.status === true && value.trim().length === 0);
    if (questionFileName) {
      setValue(questionFileName);
      setIsReadOnly(true);
    }
  }, [error?.status, value]);

  return (
    <div>
      <Box sx={{ width: '100%' }}>
        <TextField
          error={errorStatus}
          fullWidth
          required
          label="Question FileName"
          helperText={errorStatus ? 'Question FileName is required!' : ''}
          id="fullWidth"
          InputProps={{
            readOnly: isReadOnly,
          }}
          variant="filled"
          margin="dense"
          value={value}
          onChange={(e) => {
            handleChange(e.target.value);
          }}
        />
      </Box>
    </div>
  );
};

export default QuestionFileName;
