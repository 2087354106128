import gql from 'graphql-tag';

export default gql`
  mutation createContent(
    $content: AWSJSON!
    $fileName: String!
    $contentMapId: String!
  ) {
    createContent(
      newContent: {
        content: $content
        fileName: $fileName
        contentMapId: $contentMapId
      }
    ) {
      cmsId
      content
      fileName
    }
  }
`;
