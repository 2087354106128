import gql from 'graphql-tag';

export default gql`
  query getContentById($cmsId: String!) {
    getContentById(cmsId: $cmsId) {
      cmsId
      content
      fileName
    }
  }
`;
