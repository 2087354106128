import stores from './stores';
import FlashcardCreateContainer from './FlashcardCreateContainer';
import DeckTitleCreate from '../services/DeckTitleCreate';
import DeckTitleUpdate from '../services/DeckTitleUpdate';
import CardCreateService from '../services/CardCreateService';
import CardUpdateService from '../services/CardUpdateService';
import CardDeleteService from '../services/CardDeleteService';
import DeckPublish from '../services/DeckPublish';

const FlashcardCreate = {
  path: ['/flashcards/create'],
  exact: true,
  services: [
    new DeckTitleCreate(),
    new DeckTitleUpdate(),
    new CardCreateService(),
    new CardUpdateService(),
    new CardDeleteService(),
    new DeckPublish(),
  ],
  stores,
  component: FlashcardCreateContainer,
};

export default FlashcardCreate;
