import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';

interface FileDrawerDelegate {
  updateFlow?: boolean;
  onClose: () => void;
  title: string;
  altText?: string;
  onAltTextChange: (value: string) => void;
  onSaveClick: () => void;
  onUpdateClick: () => void;
  imageUrl: string;
}

const FileDrawer: React.FunctionComponent<FileDrawerDelegate> = ({
  title,
  altText,
  updateFlow = false,
  onClose,
  onAltTextChange,
  onSaveClick,
  onUpdateClick,
  imageUrl,
}) => {
  const handleChange = (e) => {
    onAltTextChange(e.target.value);
  };

  return (
    <>
      <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <Box
          component="img"
          src={imageUrl}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '0 auto',
            height: '55vh',
          }}
        />
        <TextField
          id="filled-multiline-static"
          label="Image Alternative Text"
          multiline
          rows={3}
          value={altText}
          variant="filled"
          inputRef={(input: HTMLInputElement) => {
            if (input != null) {
              input.focus();
            }
          }}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            margin: '0 auto',
            mt: 2,
            width: 400,
          }}
          onChange={(e) => {
            handleChange(e);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Close
        </Button>
        <Button
          sx={{ mx: 2 }}
          variant="contained"
          component="label"
          onClick={updateFlow ? onUpdateClick : onSaveClick}
          disabled={!altText}
        >
          {updateFlow ? 'Update' : 'Save'}
        </Button>
      </DialogActions>
    </>
  );
};

export default FileDrawer;
