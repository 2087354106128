import { ApolloError, FetchResult } from '@apollo/client';
import ulog from 'ulog';
import GetQuestions from '../graphql/QueryGetContent';
import MutationSignedQuestions from '../graphql/MutationSignContent';
import GraphqlBaseService from '../GraphqlBaseService';
import persitantStore from '../../../../../common/persistantStore';
import { getQuestion } from './RegulasQuestionApiService';

const log = ulog('QuestionService');

type QuestionEvent = {
  id: string;
};

export default class QuestionService extends GraphqlBaseService {
  constructor(eventName: string) {
    super(eventName, 'question');
  }

  // Use QueryGetContent instead
  async getSignedContent(content: any) {
    const response: any = await this.mutate({
      mutation: MutationSignedQuestions,
      variables: {
        content: JSON.stringify(content),
        apiType: 'questions',
        domain: window.location.hostname,
        userId: persitantStore.getUserdata().profile.sub,
      },
    });

    return JSON.parse(response.data?.signingContent?.content);
  }

  retrieveQuestions(questionReferences: Array<any>): Promise<any> {
    return Promise.all(
      questionReferences
        .map((question: any) => {
          return {
            query: GetQuestions,
            variables: {
              cmsId: question.cmsId,
            },
          };
        })
        .map((graphql: any) => this.query(graphql))
    );
  }

  async loadQuestions(questionReferences: Array<any>): Promise<any> {
    const isRegulasEnabled = true;
    let questions;

    if (isRegulasEnabled) {
      const responses: any = await getQuestion(
        questionReferences[0].cmsId,
        'wip'
      );
      const questionArray = [];
      const content =
        typeof responses.data.content === 'string'
          ? JSON.parse(responses.data.content)
          : responses.data.content;
      questionArray[0] = {
        ...content,
        response_id: responses.data.id,
      };
      questions = questionArray;
    } else {
      const responses: any = await this.retrieveQuestions(questionReferences);
      questions = await responses.map((response: any) => {
        const content = JSON.parse(response?.data?.getContentById?.content);
        return {
          ...content,
          response_id: response?.data?.getContentById?.cmsId,
        };
      });
    }

    return this.getSignedContent({
      type: 'local_practice',
      state: 'initial',
      questions,
      showCorrectAnswers: true,
      show_distractor_rationale: {
        per_question: 'always',
        per_response: 'always',
      },
      beta_flags: {
        lds: false,
      },
    });
  }

  // eslint-disable-next-line class-methods-use-this
  handleEvent(event: QuestionEvent): Promise<any> {
    return this.loadQuestions([{ cmsId: event.id }]);
  }

  // eslint-disable-next-line class-methods-use-this
  handleError(error: ApolloError): void {
    log.error(error);
  }

  // eslint-disable-next-line class-methods-use-this
  handleResponse(response: FetchResult<any>): void {
    this.getStore().questionList.setData(response);
  }
}
