const captureRouteRedirection = (route) => {
  if (
    route !== 'signin-oidc' &&
    (route === 'projects' || route === 'contentMaps')
  ) {
    window.localStorage.setItem('beforeRedirect', route);
  }
  if (route === 'signin-oidc') {
    return window.localStorage.getItem('beforeRedirect');
  }
  return route;
};

export const getPath = () => {
  let route = window.location.pathname.split('/')[1];
  route = captureRouteRedirection(route);
  const result =
    route === 'projects'
      ? '/projects/:projectId/questions/create'
      : '/contentMaps/:contentMapId/questions/create';

  return [result];
};

export const getParameterByPath = () => {
  let route = window.location.pathname.split('/')[1];
  route = captureRouteRedirection(route);
  const parameter = route === 'projects' ? 'projectId' : 'contentMapId';

  return parameter;
};
