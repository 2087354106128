import QuestionEditor from './QuestionEditor';
import * as constants from '../../../../../common/constants';

const initConfig = {
  widget_type: 'response',
  user: {
    id: 'hgangadhar',
  },
  ui: {
    undo_redo_button: false,
    change_button: false,
    help_button: false,
    hide_asset_input_field: false,
    live_score_button: false,
    search_field: false,
    source_button: false,
    layout: {
      global_template: 'custom',
    },
  },
};

export default class Preview extends QuestionEditor {
  constructor() {
    super(constants.QUESTION_PREVIEW_SERVICE);
  }

  async handleEvent(_: any, event: any) {
    if (event.type === 'custom') {
      return;
    }
    super.load(`preview_${event.id}`, {
      ...initConfig,
      widget_json: event.content,
    });
  }
}
