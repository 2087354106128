import { BaseService } from '@wiley-flint/framework';
import anylogger from 'anylogger';
import QuestionService from './QuestionService';
import restyleCheckAnswerButton from '../../applyCheckAnswerButtonStyles';

const log = anylogger('GraphqlBaseService');

let questionApp: any;

const callbacks = {
  errorListener: (e: any) => {
    // Adds a listener to all error codes....
    log.error(`Error Code: ${e.code} Message: ${e.msg}`);
    log.error('Error Detail ', e.detail);
  },

  readyListener: () => {
    log.info('Learnosity Questions API is ready');

    const question =
      questionApp.questions()[Object.keys(questionApp.questions())[0]];

    question.on('validated', () => {
      const questionResponseId: string = question.getQuestion()?.response_id;

      restyleCheckAnswerButton(questionResponseId, 'disabled');
    });

    question.on('changed', () => {
      const responseId: string = question.getQuestion()?.response_id;
      restyleCheckAnswerButton(responseId, '');
    });
  },

  labelBundle: {
    loadingInfo: 'Loading page...',
    play: 'play',
    checkAnswer: 'CHECK ANSWER',
  },

  saveSuccess: (responseIds: any) => {
    responseIds.forEach((responseId: string) =>
      log.info('Responses saved : ', responseId)
    );
  },

  saveError: (e: any) => {
    log.error('Save failed', e);
  },

  saveProgress: (progress: any) => {
    log.info('Save progress', progress);
  },
};

export default class QuestionsAPI extends BaseService {
  constructor(eventName: string) {
    super(eventName, 'question');
    console.log('eventName', eventName);
  }

  static async load(cmsId: string, content: any) {
    const questionList = await new QuestionService('').loadQuestions([
      { cmsId },
    ]);

    questionApp = window?.LearnosityApp.init(questionList, callbacks);
  }
}
