import EditContainer from './EditContainer';
import QuestionGetService from '../shared/services/QuestionGetService';
import QuestionUpdateService from '../shared/services/QuestionsUpdateService';
import EditEditorUIService from '../shared/services/EditorUIService/Edit';

const QuestionEdit = {
  path: ['/questions/:cmsid/edit'],
  exact: true,
  services: [
    new EditEditorUIService(),
    new QuestionGetService(),
    new QuestionUpdateService(),
  ],
  stores: [],
  component: EditContainer,
};

export default QuestionEdit;
