import Home from './Home';
import FlashcardCreate from './Flashcards/Create';
import QuestionCreate from './Questions/Create';
import QuestionPreview from './Questions/Preview';
import QuestionEdit from './Questions/Edit';
import Callback from './Callback';
// import RefreshLogin from './RefreshLogin';

export default [
  Home,
  FlashcardCreate,
  //  RefreshLogin,
  Callback,
  QuestionCreate,
  QuestionPreview,
  QuestionEdit,
];
