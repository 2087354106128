import { ORIGINAL_PATH } from './constants';
import env from './env';

const sessionStore = {
  get: (key) => {
    return window.sessionStorage.getItem(key);
  },
  set: (key, val) => {
    return window.sessionStorage.setItem(key, val);
  },
  remove: (key) => {
    return window.sessionStorage.removeItem(key);
  },
};

const localStore = {
  get: (key) => {
    return window.localStorage.getItem(key);
  },
  set: (key, val) => {
    return window.localStorage.setItem(key, val);
  },
  remove: (key) => {
    return window.localStorage.removeItem(key);
  },
};

const getUserdata = () => {
  return JSON.parse(
    sessionStore.get(`oidc.user:${env.OIDC_AUTH}:${env.OIDC_CLIENT_ID}`)
  );
};

const setOrginalPath = (path) => {
  localStore.set(ORIGINAL_PATH, path);
};

const getOrginalPath = () => {
  return localStore.get(ORIGINAL_PATH);
};

const clearOrginalPath = () => {
  localStore.remove(ORIGINAL_PATH);
};

const persitantStore = {
  getUserdata,

  clearOrginalPath,
  getOrginalPath,
  setOrginalPath,
};

export default persitantStore;
