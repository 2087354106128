import stores from '../shared/stores';
import CreateContainer from './CreateContainer';
import QuestionCreateService from '../shared/services/QuestionCreateService';
import CreateEditorUIService from '../shared/services/EditorUIService/Create';
import SignRequest from '../shared/services/ImageUploaderService/SignRequest';
import ImageUploadRequest from '../shared/services/ImageUploaderService/ImageUpload';
import PostMessageService from '../shared/services/PostMessageservice';
import { getPath } from '../../../common/utils/RouterPathUtility';

const QuestionCreate = {
  path: getPath(),
  exact: true,
  services: [
    new CreateEditorUIService(),
    new QuestionCreateService(),
    new SignRequest(),
    new ImageUploadRequest(),
    new PostMessageService(),
  ],
  stores,
  component: CreateContainer,
};

export default QuestionCreate;
