import { BaseService, Framework } from '@wiley-flint/framework';
import * as constants from '../../../common/constants';
import Toast from '../../../common/utils/toastUtility';
import { updateFlashcard } from './APIService/FlashcardAPI';

export default class CardUpdateService extends BaseService {
  constructor() {
    super(constants.FLASHCARD_UPDATE_REQUEST, 'deck');
  }

  async handleEvent(_: any, event: any) {
    const { id, actualId, term, frontType, definition, backType, cardDeckId } =
      event;
    try {
      const response = await updateFlashcard(
        cardDeckId,
        actualId,
        term,
        definition
      );
      this.getStore('deck').updateCard({
        id,
        actualId: response?.data?.id,
        term: response?.data?.frontText,
        frontType,
        definition: response?.data?.backText,
        backType,
        saved: true,
      });

      if (response?.data) {
        Toast.success(`Card updated successfully: Id ${response?.data?.id}`);
      } else {
        throw new Error(response);
      }

      const publish = this.getStore('deck').publishStatus;

      if (publish) {
        Framework.sendEvent(constants.DECK_PUBLISH_REQUEST, {
          cardDeckId,
        });
        this.getStore('deck').setPublish(false);
      }
    } catch (error) {
      Toast.error(`Failed to save the card: ${error?.message}`);
    }
  }
}
