import { BaseService } from '@wiley-flint/framework';
import * as constants from '../../../common/constants';
import Toast from '../../../common/utils/toastUtility';
import { publishDeck } from './APIService/FlashcardAPI';

export default class DeckPublish extends BaseService {
  constructor() {
    super(constants.DECK_PUBLISH_REQUEST, 'deck');
  }

  async handleEvent(_: any, event: any) {
    const { cardDeckId } = event;
    try {
      if (!this.getStore('deck').publishedVersionId) {
        const response = await publishDeck(cardDeckId);

        this.getStore('deck').setPublishedVersion(response?.data?.version);
        this.getStore('deck').resetDeck();

        if (response?.data) {
          Toast.success(
            `Deck published successfully: version ${response?.data?.version}`
          );
        } else {
          throw new Error(response);
        }
      }
    } catch (error) {
      Toast.error(`Failed to publish the Deck: ${error?.message}`);
    }
  }
}
