import axios from 'axios';
import env from '../../../../../common/env';

const options = {
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': env.REGULAS_CLIENT_SECRET,
  },
};

/**
 * Saves questions in CMS via Regulas API
 * @param data: RegulasQuestionPayload regulas save endpoint payload
 * @returns Object a question generared by Regulas API
 *
 */
export const saveRegulasQuestion = async (data: RegulasQuestionPayload) => {
  const res = await axios.post(
    `${env.REGULAS_BASE_URL}/questions`,
    data,
    options
  );
  return res;
};

/**
 * Retrieves a Question By a given Id
 * @returns Object a question by Regulas API
 * @param id
 * @param type
 */
export const getQuestion = async (id: string, type: string) => {
  const res = await axios.get(
    `${env.REGULAS_BASE_URL}/questions/${id}/${type}`,
    options
  );
  return res;
};

/**
 * Retrieves a Question By a given Id
 * @returns Object a question by Regulas API
 * @param id
 * @param data
 */
export const updateQuestion = async (id: string, data) => {
  const res = await axios.put(
    `${env.REGULAS_BASE_URL}/questions/${id}`,
    data,
    options
  );
  return res;
};

/**
 * Retrieves a Question By a given Id
 * @returns Object a question by Regulas API
 * @param id
 * @param productId
 */
export const publishQuestionById = async (id: string) => {
  const res = await axios.post(
    `${env.REGULAS_BASE_URL}/questions/${id}`,
    {},
    options
  );
  return res;
};
