import React, { useEffect, useState } from 'react';
import EventBus from 'js-event-bus';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { Theme } from '@mui/material';
import CorrectnessAlert from '../../../../common/components/CorrectnessAlert';

const MAX_TRIES = 3;

class ModifiedEventBus extends EventBus {
  trigger = (event, obj) => {
    this.emit(event, null, obj);
  };
}

const events = new ModifiedEventBus();

const facade = {
  resetValidationUI: () => null,
};

const Root = styled(Box)(({ theme }) => ({
  margin: '0 auto',
  marginTop: theme.spacing(5),
}));

const CustomButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
}));

interface PreviewCustomProps {
  id: string;
  content: any;
}

const PreviewCustom: React.FunctionComponent<PreviewCustomProps> = ({
  id,
  content,
}) => {
  const [score, setScore] = useState<number>(0);
  const [correct, setCorrect] = useState<boolean>(false);
  const [tryAgain, setTryAgain] = useState<boolean>(false);
  const [tries, setTries] = useState<number>(0);

  const handleTryAgainClick = () => {
    facade.resetValidationUI();
    setTryAgain(false);
  };

  const handleCheckAnswerClick = () => {
    const newTries = tries + 1;
    if (score === 1) {
      events.emit('validate', null, {
        showAnswer: true,
      });
      setCorrect(true);
    } else {
      if (newTries >= MAX_TRIES) {
        events.emit('validate', null, {
          showAnswer: true,
          showCorrectAnswers: true,
        });
      } else {
        setTryAgain(true);
        events.emit('validate', null, {
          showAnswer: true,
        });
      }
      setTries(newTries);
    }
  };

  useEffect(() => {
    window.LearnosityAmd = {
      define: (deps: string[], func: () => void) => {
        define(`${content.type}/${content.custom_type}`, deps, func);
        // eslint-disable-next-line import/no-dynamic-require
        requirejs(
          ['jquery-v1.10.2', `${content.type}/${content.custom_type}`],
          (jQuery, custom) => {
            const question = { ...content, response_id: 'some-id-in-random' };
            const response = { value: {} };
            let scorer = new custom.Scorer(question, response);

            events.on('changed', (evt) => {
              scorer = new custom.Scorer(question, evt);
              const newScore = scorer.score();
              const maxScore = scorer.maxScore();
              setScore(newScore / maxScore);
            });

            const questionInstance = new custom.Question(
              {
                question,
                events,
                getFacade: () => {
                  return facade;
                },
                $el: jQuery(`.custom_preview_${id}`),
              },
              {
                renderComponent: () => ({}),
              }
            );
          }
        );
      },
    };
    if (typeof content.js === 'string') {
      const script = document.createElement('script');
      script.setAttribute('type', 'text/javascript');
      script.setAttribute('src', content.js);
      document.head.append(script);
    }
  }, []);

  return (
    <Root
      style={{
        maxWidth: content?.width ?? 1024,
      }}
    >
      <div id="customQuestionPreview" className={`custom_preview_${id}`} />

      {tryAgain ? (
        <CustomButton
          variant="contained"
          color="secondary"
          onClick={handleTryAgainClick}
        >
          Try Again
        </CustomButton>
      ) : (
        tries < MAX_TRIES &&
        !correct && (
          <CustomButton
            variant="contained"
            color="primary"
            onClick={handleCheckAnswerClick}
          >
            Check Answer
          </CustomButton>
        )
      )}
      <CorrectnessAlert
        tries={tries}
        maxTries={MAX_TRIES}
        correct={correct}
        score={score}
      />
    </Root>
  );
};

export default PreviewCustom;
