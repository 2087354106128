import React from 'react';
import PropTypes from 'prop-types';
import Alert from '@mui/material/Alert';

interface CorrectnessAlertProps {
  maxTries: number;
  tries: number;
  score: number;
  correct: boolean;
}

const CorrectnessAlert: React.FunctionComponent<CorrectnessAlertProps> = ({
  correct,
  tries,
  maxTries,
  score,
}) => {
  if (correct) {
    return <Alert>Congrats! You got it right!</Alert>;
  }
  if (tries > 0 && tries < maxTries) {
    return <Alert>You have {maxTries - tries} tries remaining.</Alert>;
  }
  if (tries >= maxTries) {
    if (score === 0) {
      return <Alert color="error">Incorrect! Correct answer shown.</Alert>;
    }
    return (
      <Alert color="error">Partially correct. Correct answers shown.</Alert>
    );
  }
  return null;
};

CorrectnessAlert.propTypes = {
  score: PropTypes.number.isRequired,
  tries: PropTypes.number.isRequired,
  maxTries: PropTypes.number.isRequired,
  correct: PropTypes.bool.isRequired,
};

export default CorrectnessAlert;
