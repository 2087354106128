import Question from './models/Question';
import ModalView from './models/ModalView';
import Unleash from './models/Unleash';

const questionStore = {
  name: 'question',
  spec: Question,
  saveLocally: false,
  initialState: {},
};

const modalView = {
  name: 'modalView',
  spec: ModalView,
  saveLocally: false,
  initialState: {
    show: false,
  },
};

const unleash = {
  name: 'unleash',
  spec: Unleash,
  saveLocally: false,
  initialState: {
    isRegulasEnabled: true,
    isIntractivesEnabled: false,
  },
};

export default [questionStore, modalView, unleash];
