import CallbackContainer from './CallbackContainer';

const Callback = {
  path: ['/signin-oidc'],
  exact: true,
  services: [],
  stores: [],
  component: CallbackContainer,
};

export default Callback;
