import React, { useState } from 'react';
import Box from '@mui/material/Box';
import SetNamingScreen from './DeckNamingScreen';
import FlashcardList from './FlashcardList';
import FlashcardPreviewScreen from './FlashcardPreviewScreen';

interface FlashcardSetNameProps {
  onDisplayToggle: (display: boolean) => void;
  toggle: string;
}

const FlashcardCreateScreen: React.FunctionComponent<FlashcardSetNameProps> = ({
  onDisplayToggle,
  toggle,
}) => {
  const [continueClicked, setContinueClicked] = useState(false);
  const [screenStatus, setScreenStatus] = useState(true);

  const handleContinueButtonClick = (continued: boolean) => {
    if (continued) {
      setContinueClicked(true);
      setScreenStatus(false);
      onDisplayToggle(false);
    }
  };

  const handlePreviousClick = () => {
    setScreenStatus(true);
    setContinueClicked(false);
    onDisplayToggle(true);
  };

  return (
    <Box>
      {screenStatus && (
        <SetNamingScreen onContinueButtonClick={handleContinueButtonClick} />
      )}
      {!screenStatus && toggle === 'edit' && (
        <FlashcardList
          onPreviousClick={handlePreviousClick}
          continueClicked={continueClicked}
        />
      )}
      {!screenStatus && toggle === 'preview' && <FlashcardPreviewScreen />}
    </Box>
  );
};

export default FlashcardCreateScreen;
