const qa = {
  STAGE: 'qa',
  REGULAS_BASE_URL: 'https://47hi7cfbbc.execute-api.us-east-1.amazonaws.com/qa',
  REGULAS_CLIENT_SECRET: 'ELCBVLoJN83tCIdqBqI6e8NsPgtsyrcy5GfcBNpD',
  GITLAB_PROXY_URL:
    'https://nidmcqs3g0.execute-api.us-east-1.amazonaws.com/qa/proxy',
  GITLAB_PROXY_ENVIRONMENT: 'qa',
  OIDC_AUTH: 'https://debug.external-sso.ppd.wiley.com/auth/realms/wiley',
};

export default qa;
