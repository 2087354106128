import React, { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';

const Redirect: React.FC = () => {
  const auth = useAuth();
  useEffect(() => {
    const state = { lastPage: window.location.pathname };
    auth.signinRedirect({ state });
  });
  return <div>Logging you in...</div>;
};

export default Redirect;
