import React from 'react';
import { onSnapshot } from 'mobx-state-tree';
import styled from '@mui/material/styles/styled';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Snackbar from '@mui/material/Snackbar';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { SnackbarCloseReason } from '@mui/material';
import { alertStore } from './alertStore';

const errorTimeout = 120000;
const defaultTimeout = 15000;

const initialAlertStateValues = {
  isOpen: false,
  message: '',
  type: '',
  description: {
    message: '',
    cmsId: '',
  },
};

const alertSeverity = {
  error: 'error',
};

const StyledSnackbar = styled(Snackbar)(() => ({
  width: '600px',
  height: '48px',
  padding: '6px, 8px, 6px, 16px',
}));

const AlertComponent: React.FunctionComponent = () => {
  const [alert, setAlert] = React.useState(initialAlertStateValues);

  React.useEffect(() => {
    const unsubscribe = onSnapshot(alertStore, (newSnapshot) => {
      if (!newSnapshot.alert.isRead) {
        setAlert({
          isOpen: true,
          message: newSnapshot.alert.message,
          type: newSnapshot.alert.type,
          description: {
            message: newSnapshot.alert.description.message,
            cmsId: newSnapshot.alert.description.cmsId,
          },
        });
      }
    });

    return () => unsubscribe();
  }, []);

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason
  ) => {
    if (event) event.preventDefault();
    if (reason === 'clickaway') {
      return;
    }

    alertStore.onClose();
    setAlert(initialAlertStateValues);
  };

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const StyledAlert = styled(Alert)(() => ({
    width: '100%',
    backgroundColor: `${
      alertSeverity[alert.type] === 'error' ? '#B92D80' : '#3E8453'
    }`,
    textAlign: 'left',
    '& .MuiAlert-icon': {
      flexDirection: 'column',
      justifyContent: 'center',
    },
    '& .MuiAlert-action': {
      flexDirection: 'column',
      justifyContent: 'center',
    },
  }));

  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <StyledSnackbar
      open={alert.isOpen}
      autoHideDuration={alert.type === 'error' ? errorTimeout : defaultTimeout}
      onClose={(e, reason) => handleClose(e, reason)}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      {alert.isOpen ? (
        <StyledAlert
          severity={alertSeverity[alert.type]}
          onClose={(e) => handleClose(e)}
          action={alertSeverity[alert.type] === 'error' ? action : null}
        >
          {alert.message}
          <Typography
            variant="body2"
            component={Link}
            href={`${window.location.origin}/questions/${alert.description.cmsId}/preview`}
            target="_blank"
            rel="noreferrer"
            underline="hover"
            style={{ fontWeight: 'bold', color: 'inherit' }}
          >
            {alert.description.message}
          </Typography>
        </StyledAlert>
      ) : null}
    </StyledSnackbar>
  );
};

export default AlertComponent;
