import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import { Framework } from '@wiley-flint/framework';
import QuestionsAPI from '../../shared/services/QuestionAPIService/Question';
import * as constants from '../../../../common/constants';

const initConfig = {
  widget_type: 'response',
  user: {
    id: 'hgangadhar',
  },
  ui: {
    undo_redo_button: false,
    change_button: false,
    help_button: false,
    hide_asset_input_field: false,
    live_score_button: false,
    search_field: false,
    source_button: false,
    layout: {
      global_template: 'custom',
    },
  },
  renderSubmitButton: true,
  validateOnSubmit: true,
  attribute_overrides: {
    instant_feedback: true,
  },
};

interface PreviewLearnosityProps {
  id: string;
  content: any;
}

const PreviewLearnosity: React.FunctionComponent<PreviewLearnosityProps> = ({
  id,
  content,
}) => {
  let timeOut: number;
  const [dimension, setDimension] = useState({
    height: 0,
    width: 0,
  });
  const ref = useRef(null);
  const setQuestionPreviewDimension = (timeOutValue: number) => {
    if (timeOut) {
      clearTimeout(timeOut);
    }
    setTimeout(() => {
      setDimension({
        height: ref.current.clientHeight,
        width: ref.current.clientWidth,
      });
    }, timeOutValue);
  };
  useEffect(() => {
    // should append questionAPI script
    const script = document.createElement('script');
    script.setAttribute(
      'src',
      'https://questions-va.learnosity.com/?v2022.2.LTS'
    );
    document.head.append(script);

    script.onload = () => {
      if (content) {
        QuestionsAPI.load(id, {
          ...initConfig,
          widget_json: content,
        });
      }
    };

    return () => script.remove();
  }, []);

  useEffect(() => {
    setQuestionPreviewDimension(3000);
  }, []);

  useEffect(() => {
    const handleWindowResize = () => {
      setQuestionPreviewDimension(1000);
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    const { body, documentElement } = document;
    setTimeout(() => {
      if (
        body.clientHeight !== 0 ||
        (dimension.width !== 0 && ref.current.id === `content-box-${id}`)
      ) {
        const heightVal =
          documentElement.scrollHeight === documentElement.clientHeight
            ? documentElement.offsetHeight
            : documentElement.scrollHeight;
        console.log('heightValue', heightVal);
        const payload: PreviewPayload[] = [
          {
            id,
            modelType: 'Question',
            version: 'mock-version',
            currentVersion: {
              modelType: 'Question',
              id: '871edec1-fb32-4993-b523-9e63a28f1648',
            },
            _links: {
              edit: {
                href: `${window.location.origin}/questions/${id}/edit`,
              },
              preview: {
                href: `${window.location.origin}/questions/${id}/preview`,
              },
              SourceRendition: {
                href: 'LINK TO ContentAPI? or CPub API?',
              },
            },
            dimension: {
              height: heightVal,
              width: dimension.width,
            },
          },
        ];
        console.log('payload: ', payload);
        Framework.sendEvent(constants.HTML_POST_MESSAGE, {
          payload,
        });
      }
    }, 2000);
  }, [dimension.height, dimension.width]);

  return (
    <Box
      style={{
        maxWidth: 800,
      }}
      id={`content-box-${id}`}
      ref={ref}
    >
      <div
        id="questionPreview"
        className={`learnosity-response question-${id}`}
        style={{ display: 'none' }}
      />
      <span data-lrn-qe-layout-preview-panel />
    </Box>
  );
};

export default PreviewLearnosity;
