import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { Framework } from '@wiley-flint/framework';
import { useParams } from 'react-router';
import { Box } from '@mui/material';
import Preview from './components/Preview';
import env from '../../../common/env';

const PreviewContainer: React.FunctionComponent = () => {
  const { question, unleash } = Framework.useStore();
  const params = useParams<{ cmsid: string }>();
  const cmsId = params.cmsid;
  const featureRegulasEnabled = true;

  useEffect(() => {
    console.log(`Window location: + ${window.location}`);
    const url = `${env.AQUILA_URL}/questions/${cmsId}/preview`;
    window.location.replace(url);
    console.log(
      `Preview page is about to fetch the data for the CMS ID: ${cmsId}`
    );
    unleash.set(
      {
        isRegulasEnabled: featureRegulasEnabled,
      },
      'isRegulasEnabled'
    );
    question.fetch({
      cmsId,
    });
  }, []);

  if (question.content && cmsId === question.cmsId) {
    console.log(`Preview page is rendering with CMS ID: ${cmsId}`);
  }

  return (
    <Box id="custom-styled">
      {question.content && cmsId === question.cmsId ? (
        <Preview id={cmsId} content={question.content} />
      ) : null}
    </Box>
  );
};

export default observer(PreviewContainer);
