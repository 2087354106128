import { types, applySnapshot } from 'mobx-state-tree';
import { Framework } from '@wiley-flint/framework';
import anylogger from 'anylogger';

import * as constants from '../../../../common/constants';
import imageMapper from '../mappers/imageMapper';
import MetaData from './Metadata';

const log = anylogger('Image');

const Image = types
  .model('Image', {
    editing: types.boolean, // Open/ close image editor popup window
    uploading: types.boolean,
    updateFlow: types.optional(types.boolean, false),
    metadata: types.optional(MetaData, () => MetaData.create({})),
    returnType: types.optional(types.string, ''),
    altText: types.optional(types.string, ''),
  })
  .volatile(() => ({
    file: null as File | null,
  }))
  .actions((self) => {
    return {
      onUploaded: () => {
        log.info('Successfully uploaded!');
      },
      setReturnType(returnType: string) {
        self.returnType = returnType;
      },
      setImageAltText(altText: string) {
        self.altText = altText;
      },
      setEditing(editing: boolean) {
        self.editing = editing;
      },
      setUploading(uploading: boolean) {
        self.uploading = uploading;
      },
      setUrl(url: string) {
        self.metadata.url = url;
      },
      setFile(file: File) {
        self.file = file;
        self.metadata = {
          name: file.name,
          type: file.type,
          url: null,
          height: null,
          width: null,
        };
        Framework.sendEvent(constants.IMAGE_SIGNURL_REQUEST, {
          fileName: file.name,
          fileType: file.type,
        });
      },

      update(data: any) {
        log.debug({ ...self, ...imageMapper(data) });
        applySnapshot(self, { ...self, ...imageMapper(data) });

        if (self.returnType === 'HTML') {
          // For the Rich Text Editor Images
          const { metadata } = self;
          this.onUploaded(
            `<img src="${metadata.url}" ${
              metadata.width || metadata.height
                ? `style="${
                    metadata.height ? `height: ${metadata.height}px` : ''
                  }; ${metadata.width ? `width: ${metadata.width}px` : ''}"`
                : ''
            }
            ${metadata.width ? `width="${metadata.width}"` : ''} ${
              metadata.height ? `height="${metadata.height}"` : ''
            } alt="${self.altText}"/>`
          );
        } else {
          // For the Image types which requires an image to be uploaded.
          this.onUploaded(self.metadata.url);
        }
      },

      close() {
        this.onUploaded();
      },

      assetRequestFunction(mediaRequested, returnType, callback, attributes) {
        log.debug(returnType);
        console.log(
          'assetRequestFunction',
          mediaRequested,
          returnType,
          attributes
        );
        const updateFlow = attributes.alt !== null;
        if (mediaRequested === 'image') {
          applySnapshot(self, {
            editing: updateFlow,
            uploading: !updateFlow,
            metadata: {
              ...self.metadata,
              url: attributes.src,
              height: attributes.height,
              width: attributes.width,
            },
            updateFlow,
            altText: attributes.alt ?? undefined,
          });
          this.onUploaded = callback;
          this.setReturnType(returnType);
        }
      },
    };
  });
export default Image;
