const poc = {
  PROXIMA_BASE_URL:
    'https://haobpqk6cveztnccmg6qxyltai.appsync-api.us-east-1.amazonaws.com/graphql',
  STAGE: 'poc',
  GITLAB_PROXY_URL:
    'https://b4f5m1pi06.execute-api.us-east-1.amazonaws.com/poc/proxy',
  GITLAB_PROXY_ENVIRONMENT: 'poc',
  AQUILA_URL: 'https://dev.aquila.wiley.host',
};

export default poc;
