import { BaseService } from '@wiley-flint/framework';
import * as constants from '../../../common/constants';
import Toast from '../../../common/utils/toastUtility';
import { updateDeckById } from './APIService/FlashcardAPI';

export default class DeckTitleUpdate extends BaseService {
  constructor() {
    super(constants.DECK_TITLE_UPDATE_REQUEST, 'deck');
  }

  async handleEvent(_: any, event: any) {
    const { deckId, title } = event;
    try {
      const response = await updateDeckById(deckId, title);
      this.getStore('deck').setTitle(response?.data?.title);
      this.getStore('deck').setDeckId(response?.data?.id);

      if (response?.data) {
        Toast.success('Deck title updated successfully.');
      } else {
        throw new Error(response);
      }
    } catch (error) {
      Toast.error(`Failed to update Deck title: ${error?.message}`);
    }
  }
}
