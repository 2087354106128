import { applySnapshot, types, cast } from 'mobx-state-tree';

type UnleashData = {
  isRegulasEnabled?: boolean;
  isIntractivesEnabled?: boolean;
};

const Unleash = types
  .model('Unleash', {
    isRegulasEnabled: types.boolean,
    isIntractivesEnabled: types.boolean,
  })
  .actions((self) => ({
    set(data: UnleashData, type) {
      if (data) {
        self[type] = cast(true);
        applySnapshot(self, { ...self, ...data });
      }
    },
    getStatus(type) {
      return self[type];
    },
  }));

export default Unleash;
