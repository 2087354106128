import { Framework } from '@wiley-flint/framework';
import * as constants from '../../../../../common/constants';
import MutationCreateImageSignedUrl from '../graphql/MutationCreateImageSignedUrl';
import GraphqlBaseService from '../GraphqlBaseService';

export default class SignRequest extends GraphqlBaseService {
  constructor() {
    super(constants.IMAGE_SIGNURL_REQUEST, 'question');
  }

  async handleEvent(_: any, event: any) {
    const { fileName, fileType } = event;
    const response: any = await this.mutate({
      mutation: MutationCreateImageSignedUrl,
      variables: {
        fileType,
        fileName,
      },
    });
    const signedRequest = response?.data?.createImageSignedUrl?.signedRequest;
    const url = response?.data?.createImageSignedUrl?.url;
    this.getStore('question').selectedImage.setUrl(url);
    Framework.sendEvent(constants.IMAGE_UPLOAD_REQUEST, { signedRequest });
    this.getStore('question').updateContent({
      image: {
        src: url,
        width: 545,
        height: 337,
      },
    });
  }
}
