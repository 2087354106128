import stores from './stores';
import AuthoringContainer from './AuthoringContainer';
import env from '../../common/env';

const Authoring = {
  path: [
    (env.STAGE === 'local' ||
      env.STAGE === 'poc' ||
      env.STAGE === 'dev' ||
      env.STAGE === 'qa') &&
      '/test',
  ],
  exact: true,
  services: [],
  stores,
  component: AuthoringContainer,
};

export default Authoring;
