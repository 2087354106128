import { Box } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { Framework } from '@wiley-flint/framework';
import { useParams } from 'react-router';
import { useFlag } from '@unleash/proxy-client-react';
import Edit from './components/Edit';
import connectImageDrawer from '../shared/hoc/connectImageDrawer';
import * as constants from '../../../common/constants';

const EditContainer: React.FunctionComponent = () => {
  const { question, unleash } = Framework.useStore();
  const params = useParams<{ cmsid: string }>();
  const cmsId = params.cmsid;
  const featureRegulasEnabled = true;
  const featureInteractivesEnabled = useFlag(
    constants.FEATURE_FLAG_INTERACTIVES
  );

  useEffect(() => {
    // should append questionEditorAPI script
    const script = document.createElement('script');
    script.setAttribute(
      'src',
      'https://questioneditor.learnosity.com/?v2022.2.LTS'
    );
    document.head.append(script);

    script.onload = () => {
      unleash.set(
        {
          isRegulasEnabled: featureRegulasEnabled,
        },
        'isRegulasEnabled'
      );
      unleash.set(
        {
          isIntractivesEnabled: featureInteractivesEnabled,
        },
        'isIntractivesEnabled'
      );
      question.edit({
        cmsId,
      });
    };

    return () => script.remove();
  }, []);
  return (
    <Box>
      <Edit className={`edit_${cmsId}`} />
    </Box>
  );
};

export default observer(connectImageDrawer(EditContainer));
