import { Framework } from '@wiley-flint/framework';
import GraphqlBaseService from './GraphqlBaseService';
import * as constants from '../../../../common/constants';
import createContent from './graphql/MutationCreateContent';
import createContentByProductId from './graphql/MutationCreateContentByProductId';
import Toast from '../../../../common/utils/toastUtility';
import { getParameterByPath } from '../../../../common/utils/RouterPathUtility';
import {
  saveRegulasQuestion,
  publishQuestionById,
} from './QuestionAPIService/RegulasQuestionApiService';
import env from '../../../../common/env';

const FOUND_STATUS_CODE = 302;

export default class QuestionCreateService extends GraphqlBaseService {
  constructor() {
    super(constants.QUESTION_CREATE_REQUEST, 'question');
  }

  async handleEvent(eventName: any, event: any) {
    const isRegulasEnabled = true;
    const path = getParameterByPath();
    const argument = path === 'projectId' ? 'productId' : path;
    const parameter = {};
    parameter[`${argument}`] = event[`${path}`];

    if (isRegulasEnabled) {
      const regulasPayload = {
        title: event.fileName,
        name: event.fileName,
        content:
          typeof event.content === 'string'
            ? event.content
            : JSON.stringify(event.content),
        status: constants.QUESTION_STATUS_WORKING_PROGRESS,
        provider: constants.QUESTION_TYPE_LEARNOSITY,
        groupId: event[`${path}`],
      };

      saveRegulasQuestion(regulasPayload)
        .then(async (response) => {
          const { id } = response.data;
          const published = await publishQuestionById(id);
          const publishedData = published.data;
          const payload: PostPayload[] = [
            {
              id: publishedData.id,
              modelType: 'Question',
              currentVersion: {
                modelType: 'Question',
                id: '871edec1-fb32-4993-b523-9e63a28f1648',
              },
              _links: {
                edit: {
                  href: `${env.AQUILA_URL}/questions/${publishedData.id}/edit`,
                },
                preview: {
                  href: `${env.AQUILA_URL}/questions/${publishedData.id}/preview`,
                },
                SourceRendition: {
                  href: 'LINK TO ContentAPI? or CPub API?',
                },
              },
            },
          ];
          Framework.sendEvent(constants.HTML_POST_MESSAGE, {
            payload,
          });

          Toast.success('Question created successfully: ', {
            message: event.fileName,
            cmsId: id,
          });
        })
        .catch((error) => {
          const occurredError = error.response.data;

          Toast.error(
            `Failed to save (${
              occurredError?.message || 'Internal server error'
            }). Report the error to your project contact.`
          );
        });
    } else {
      const response: any = await this.mutate(
        {
          mutation:
            path === 'projectId' ? createContentByProductId : createContent,
          variables: {
            content: JSON.stringify(event.content),
            fileName: event.fileName,
            ...parameter,
          },
        },
        (error) => {
          const occurredError = error?.graphQLErrors
            ? error?.graphQLErrors[0]
            : null;

          let occurredErrorObject;

          try {
            occurredErrorObject = JSON.parse(occurredError?.message);
          } catch (err) {
            console.log('Error message is not found', err);
          }

          if (
            occurredErrorObject &&
            occurredErrorObject.statusCode === FOUND_STATUS_CODE
          ) {
            this.getStore('question').update({
              cmsId: occurredErrorObject.cmsId,
            });
            this.getStore('modalView').set({
              show: true,
            });
          } else {
            Toast.error(
              `Failed to save (${
                occurredError?.message || 'Internal server error'
              }). Report the error to your project contact.`
            );
          }
        }
      );

      const mutation = response.data.createContent
        ? 'createContent'
        : 'createContentByProductId';

      const { cmsId } = response.data[`${mutation}`];
      const payload: PostPayload[] = [
        {
          id: cmsId,
          modelType: 'Question',
          currentVersion: {
            modelType: 'Question',
            id: '871edec1-fb32-4993-b523-9e63a28f1648',
          },
          _links: {
            edit: {
              href: `${window.location.origin}/questions/${cmsId}/edit`,
            },
            preview: {
              href: `${window.location.origin}/questions/${cmsId}/preview`,
            },
            SourceRendition: {
              href: 'LINK TO ContentAPI? or CPub API?',
            },
          },
        },
      ];
      Framework.sendEvent(constants.HTML_POST_MESSAGE, {
        payload,
      });

      Toast.success('Question created successfully: ', {
        message: event.fileName,
        cmsId: response.data[`${mutation}`].cmsId,
      });
    }
  }
}
