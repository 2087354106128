import React, { useEffect } from 'react';
import { Framework } from '@wiley-flint/framework';
import { useAuth } from 'react-oidc-context';

const AuthoringContainer: React.FunctionComponent = () => {
  const auth = useAuth();

  useEffect(() => {
    const state = auth.user.state as any;
    Framework.redirect(state.lastPage || '/test');
  });
  return null;
};

export default AuthoringContainer;
