import React from 'react';

interface EditProps {
  className: string;
}
const Edit: React.FunctionComponent<EditProps> = ({ className }) => {
  return (
    <div>
      <div
        id="questionEdit"
        className={className}
        data-testid="question-edit"
        style={{ display: 'none' }}
      />
    </div>
  );
};

export default Edit;
