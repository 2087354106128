import { GraphQLErrors, NetworkError } from '@apollo/client/errors';
import { GraphQLError } from 'graphql';
import Toast from '../utils/toastUtility';

export interface ErrorResponse {
  graphQLErrors?: GraphQLErrors;
  networkError?: NetworkError;
}

interface IGraphQLError extends GraphQLError {
  errorType: string;
  message: string;
}

class ErrorHandler {
  private graphqlErrors?: GraphQLErrors;

  private networkError?: NetworkError;

  constructor(error: ErrorResponse) {
    this.graphqlErrors = error.graphQLErrors;
    this.networkError = error.networkError;
  }

  display() {
    if (this.graphqlErrors)
      this.graphqlErrors.forEach((error: IGraphQLError) => {
        // TODO: Handle other errors by error types.
        if (error.errorType === 'UnauthorizedException') {
          // window.location.href = `${window.location.origin}/refreshLogin`;
        } else if (error.errorType === 'ACCESS_DENIED') {
          // TODO: is it correcct way?
          Toast.error(error.message);
        } else {
          Toast.error(error.message || 'Internal server error!');
        }
      });

    if (this.networkError) Toast.error('Network error!');
  }
}

export default ErrorHandler;
