import gql from 'graphql-tag';

export default gql`
  mutation CreateImageSignedUrl($fileName: String!, $fileType: String!) {
    createImageSignedUrl(
      metaData: { fileName: $fileName, fileType: $fileType }
    ) {
      url
      signedRequest
    }
  }
`;
