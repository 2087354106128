import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const FlashcardPreviewScreen: React.FunctionComponent = () => {
  return (
    <Box>
      <Typography variant="h5">This is for Flashcard Preview</Typography>
    </Box>
  );
};

export default FlashcardPreviewScreen;
