import React from 'react';
import { Framework } from '@wiley-flint/framework';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react-lite';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { Grid } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  girdContainer: {
    height: '90vh',
  },
}));

const NotFoundContainer = () => {
  const { language } = Framework.useStore();
  const classes = useStyles();

  // TODO: Add fr language descriptions for title and description
  return (
    <Box>
      <Grid
        className={classes.girdContainer}
        container
        alignItems="center"
        justifyContent="center"
        direction="column"
      >
        <Typography variant="h1" component="h1">
          404
        </Typography>
        <Typography variant="h5" component="h1">
          <FormattedMessage {...language.getText('common.NOTFOUND_TITLE')} />
        </Typography>
        <Typography variant="body1" component="div">
          <FormattedMessage {...language.getText('common.NOTFOUND_DESC')} />
        </Typography>
      </Grid>
    </Box>
  );
};

export default observer(NotFoundContainer);
