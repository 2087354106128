import { Framework } from '@wiley-flint/framework';
import anylogger from 'anylogger';
import * as constants from '../../../../common/constants';
import GraphqlBaseService from './GraphqlBaseService';
import MutationUpdateContent from './graphql/MutationUpdateContent';
import Toast from '../../../../common/utils/toastUtility';
import { updateQuestion } from './QuestionAPIService/RegulasQuestionApiService';
import env from '../../../../common/env';

const log = anylogger('GraphqlBaseService');

export default class QuestionUpdateService extends GraphqlBaseService {
  constructor() {
    super(constants.QUESTION_UPDATE_REQUEST, 'question');
  }

  async handleEvent(eventName: any, event: any) {
    const updateParams: Question = { cmsId: event.cmsId };
    log.debug(event);
    const content =
      typeof event.content === 'string'
        ? event.content
        : JSON.stringify(event.content);
    if (event.content) {
      updateParams.content = content;
    }

    const isRegulasEnabled = true;
    if (isRegulasEnabled) {
      const regulasPayload = {
        title: event.fileName,
        name: event.fileName,
        content,
        status: constants.QUESTION_STATUS_WORKING_PROGRESS,
        provider: constants.QUESTION_TYPE_LEARNOSITY,
      };

      updateQuestion(updateParams.cmsId, regulasPayload)
        .then(async (response) => {
          const { id } = response.data;
          const payload: PostPayload[] = [
            {
              id,
              modelType: 'Question',
              currentVersion: {
                modelType: 'Question',
                id: '871edec1-fb32-4993-b523-9e63a28f1648',
              },
              _links: {
                edit: {
                  href: `${env.AQUILA_URL}/questions/${id}/edit`,
                },
                preview: {
                  href: `${env.AQUILA_URL}/questions/${id}/preview`,
                },
                SourceRendition: {
                  href: 'LINK TO ContentAPI? or CPub API?',
                },
              },
            },
          ];
          Framework.sendEvent(constants.HTML_POST_MESSAGE, {
            payload,
          });

          Toast.success('Question updated successfully: ', {
            message: event.fileName,
            cmsId: id,
          });
        })
        .catch((error) => {
          const occurredError = error.response.data;

          Toast.error(
            `Failed to update (${
              occurredError?.message || 'Internal server error'
            }). Report the error to your project contact.`
          );
        });
    } else {
      const response: any = await this.mutate(
        {
          mutation: MutationUpdateContent,
          variables: updateParams,
        },
        (error) => {
          const occurredError = error?.graphQLErrors
            ? error?.graphQLErrors[0]
            : null;

          try {
            const { message } = occurredError;
            Toast.error(
              `Failed to save (${
                message || 'Internal server error'
              }). Report the error to your project contact.`
            );
          } catch (err) {
            Toast.error(
              'Failed to save (Internal server error). Report the error to your project contact.'
            );
            console.log('Error message is not found', err);
          }
        }
      );

      const { cmsId } = response.data.updateContent;
      const payload: PostPayload[] = [
        {
          id: cmsId,
          modelType: 'Question',
          currentVersion: {
            modelType: 'Question',
            id: '871edec1-fb32-4993-b523-9e63a28f1648',
          },
          _links: {
            edit: {
              href: `${window.location.origin}/questions/${cmsId}/edit`,
            },
            preview: {
              href: `${window.location.origin}/questions/${cmsId}/preview`,
            },
            SourceRendition: {
              href: 'LINK TO ContentAPI? or CPub API?',
            },
          },
        },
      ];

      Framework.sendEvent(constants.HTML_POST_MESSAGE, {
        payload,
      });

      Toast.success('Question updated successfully:', {
        message: event.fileName,
        cmsId: response.data.updateContent.cmsId,
      });
    }
  }
}
