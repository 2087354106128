import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';

interface TextProps {
  text: string;
  onTextChange: (text: string) => void;
  errorStatus?: boolean;
}

const Text: React.FunctionComponent<TextProps> = ({
  text,
  onTextChange,
  errorStatus,
}) => {
  const [value, setValue] = useState('');
  const [error, setError] = useState(false);

  const handleValueChange = (e) => {
    setValue(e.target.value);
    onTextChange(e.target.value);
  };

  useEffect(() => {
    setValue(text);
  }, [text]);

  useEffect(() => {
    setError(errorStatus && value.trim().length === 0);
  }, [errorStatus, value]);

  return (
    <TextField
      error={error}
      helperText={error ? 'Please enter value here before proceeding' : ''}
      fullWidth
      margin="normal"
      id="text-type"
      size="small"
      variant="filled"
      label="Type your text"
      multiline
      value={value}
      onChange={(e) => handleValueChange(e)}
    />
  );
};

export default Text;
