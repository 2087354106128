import { alertStore } from '../components/alerts/alertStore';

class Toast {
  static error = (message: string) => {
    alertStore.set({
      message,
      type: 'error',
    });
  };

  static success = (
    message: string,
    description?: { message: string; cmsId: string }
  ) => {
    alertStore.set({ message, description, type: 'success' });
  };
}

export default Toast;
