import React, { forwardRef, useState, useImperativeHandle } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

interface DialogBoxDelegate {
  onSave: () => void;
  title: string;
  body: any;
  show: boolean;
  setShow: (show: boolean) => void;
}

const DialogBox: React.FC<DialogBoxDelegate> = ({
  title,
  body,
  onSave,
  show,
  setShow,
}) => {
  return (
    <div>
      <Dialog
        open={show}
        onClose={() => setShow(false)}
        fullWidth
        maxWidth="sm"
        aria-labelledby="responsive-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <div dangerouslySetInnerHTML={{ __html: body }} />
        </DialogContent>
        <DialogActions style={{ margin: '0px 10px', marginBottom: 10 }}>
          <Button
            variant="contained"
            component="label"
            onClick={() => setShow(false)}
          >
            Close
          </Button>
          <Button
            variant="contained"
            component="label"
            onClick={() => {
              setShow(false);
              onSave();
            }}
            style={{ marginLeft: 20 }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DialogBox;
