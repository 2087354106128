import { useFlag } from '@unleash/proxy-client-react';
import React, { useEffect, useState } from 'react';
import * as constants from '../../../common/constants';

const Authoring: React.FunctionComponent = () => {
  const [postMessage, setPostMessage] = useState('');
  const [iFrameHeight, setIFrameHeight] = useState<number>(0);

  const testFeatureFlagIntegration = useFlag(
    constants.TEST_FEATURE_FLAG_INTEGRATION
  );

  const iFrameStyles = {
    padding: '0.5rem 0.5rem',
    borderRadius: '10px',
    boxShadow: '0 0 1.875rem 0 rgb(20 55 70 / 20%)',
    borderColor: 'transparent',
  };

  useEffect(() => {
    window.addEventListener('message', function (event) {
      const { data } = event;
      if (data[0]?.modelType === 'Question') {
        if (data[0]?.id === '24311550-8550-4c52-8775-ac3e719bc78d') {
          setIFrameHeight(data[0]?.dimension?.height);
        }
        setPostMessage(JSON.stringify(data, null, 2));
      }
    });
  }, []);
  return (
    <div style={{ paddingRight: '15px' }}>
      {testFeatureFlagIntegration ? <p>Test feature flag integration</p> : null}
      <pre id="question_payload" style={{ fontSize: 'small' }}>
        {postMessage}
      </pre>
      <iframe
        style={iFrameStyles}
        id="24311550-8550-4c52-8775-ac3e719bc78d"
        width="100%"
        height={`${iFrameHeight + 35}px`}
        title="first iframe"
        src={`${window.location.origin}/questions/24311550-8550-4c52-8775-ac3e719bc78d/preview`}
      />
      <iframe
        style={iFrameStyles}
        width="100%"
        height="1000"
        title="first iframe"
        src={`${window.location.origin}/questions/18c761a0-960d-4dda-b215-1b5ec423f4ff/preview`}
      />

      <iframe
        style={iFrameStyles}
        width="100%"
        height="1000"
        title="second iframe"
        src={`${window.location.origin}/questions/f5d028bf-ae39-49c7-85f9-53d47fa7be9d/preview`}
      />

      <iframe
        style={iFrameStyles}
        width="100%"
        height="1000"
        src={`${window.location.origin}/contentMaps/9302aca3-f8a8-4314-b1cb-7ba6930a48a3/questions/create`}
        title="Create"
      />
      <iframe
        style={iFrameStyles}
        width="100%"
        height="1000"
        src={`${window.location.origin}/projects/${constants.TEST_PROJECT_ID}/questions/create`}
        title="Create"
      />
    </div>
  );
};

export default Authoring;
