import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Text from './Text';
import FillImage from './FillImage';
import FitImage from './FitImage';
import CenterImage from './CenterImage';
import Audio from './Audio';

interface CardFieldProps {
  typeValue: string;
  onTypeChange: (typeValue: string) => void;
  textValue?: string;
  onTextChange?: (textValue: string) => void;
  errorStatus?: boolean;
}

const CardField: React.FunctionComponent<CardFieldProps> = ({
  typeValue,
  onTypeChange,
  textValue,
  onTextChange,
  errorStatus,
}) => {
  const [type, setType] = useState(typeValue);
  const [text, setText] = useState(textValue);

  useEffect(() => {
    if (typeValue) {
      setType(typeValue);
    }
  }, [typeValue]);

  useEffect(() => {
    setText(textValue);
  }, [textValue]);

  const handleChange = (event: SelectChangeEvent) => {
    // setType(event.target.value as string);
    // onTypeChange(event.target.value as string);

    // Setting the type as Text for now...
    setType('Text');
    onTypeChange('Text');
  };
  const handleTextChange = (textFieldValue: string) => {
    setText(textFieldValue);
    onTextChange(textFieldValue);
  };

  return (
    <Box>
      <Select
        fullWidth
        size="small"
        value={type}
        onChange={handleChange}
        displayEmpty
      >
        <MenuItem value="Text">Text</MenuItem>
        <MenuItem value="Fill Image">Fill Image</MenuItem>
        <MenuItem value="Fit Image">Fit Image</MenuItem>
        <MenuItem value="Center Image">Center Image</MenuItem>
        <MenuItem value="Audio">Audio</MenuItem>
      </Select>
      {(() => {
        switch (type) {
          case 'Fill Image':
            return <FillImage />;
          case 'Fit Image':
            return <FitImage />;
          case 'Center Image':
            return <CenterImage />;
          case 'Audio':
            return <Audio />;
          default:
            return (
              <Text
                text={text}
                onTextChange={handleTextChange}
                errorStatus={errorStatus}
              />
            );
        }
      })()}
    </Box>
  );
};

export default CardField;
