const base = {
  PROXIMA_BASE_URL: `${window.location.origin}/graphql`,
  REGULAS_BASE_URL: 'https://19mlnrk0i0.execute-api.us-east-1.amazonaws.com',
  REGULAS_CLIENT_SECRET: '5qiUsFzM0f1wNFxa8qrQ4ZBsGjfuBdDzuhyqNP80',
  QUESTION_EDITOR_CONSUMERKEY: 'cpWfHiRKcOZyvOLK',
  OIDC_AUTH: 'https://external-sso.ppd.wiley.com/auth/realms/wiley',
  OIDC_CLIENT_ID: 'f948a2e3-a8c0-4831-8628-72e340fadf29',
  OIDC_CLIENT_SECRET: 'd9a92a9f-5268-49c5-8596-b530203ac1ee',
  OIDC_REDIRECT_URI: `${window.location.origin}/signin-oidc`,
  MEF_ORIGIN: 'https://wileypoc.rctmef.io/',
  GITLAB_PROXY_URL:
    'https://b4f5m1pi06.execute-api.us-east-1.amazonaws.com/poc/proxy',
  GITLAB_PROXY_CLIENT_KEY: 'f91dc72d-df99-4c1d-ac91-00d8323f69a5',
  GITLAB_PROXY_REFRESH_INTERVAL: 2700,
  GITLAB_PROXY_ENVIRONMENT: 'poc',
  GITLAB_PROXY_APP_NAME: 'hamilton',
  AQUILA_URL: 'https://qa.aquila.wiley.host',
};

export default base;
