import { types } from 'mobx-state-tree';

const MetaData = types.model('MetaData', {
  url: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  width: types.maybeNull(types.string),
  height: types.maybeNull(types.string),
});

export default MetaData;
