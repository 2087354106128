import anylogger from 'anylogger';
import base from './base';
import poc from './poc';
import local from './local';
import dev from './dev';
import qa from './qa';
import prod from './prod';
import uat from './uat';

const log = anylogger('env');

let environment;
switch (window.location.hostname) {
  case 'd1nqux8ozk15ys.cloudfront.net':
    log.info('poc env loaded');
    environment = { ...base, ...poc };
    break;
  case 'dev.mcsassessment.wiley.host':
  case 'dev.aiminterplay.wiley.host':
    log.info('dev env loaded');
    environment = { ...base, ...dev };
    break;
  case 'qa.mcsassessment.wiley.host':
  case 'qa.aiminterplay.wiley.host':
    log.info('qa env loaded');
    environment = { ...base, ...qa };
    break;
  case 'uat.aiminterplay.wiley.host':
    log.info('uat env loaded');
    environment = { ...base, ...uat };
    break;
  case 'aiminterplay.wiley.host':
    log.info('prod env loaded');
    environment = { ...base, ...prod };
    break;
  case 'localhost':
    log.info('local env loaded');
    environment = { ...base, ...local };
    break;
  default:
    log.info(`${window.location.hostname} not a recognized host`);
}

const env = environment;
export default env;
