/* Question's CMS CRUD operations */
export const QUESTION_CREATE_REQUEST = 'QUESTION_CREATE_REQUEST';
export const QUESTION_VIEW_REQUEST = 'QUESTION_VIEW_REQUEST';
export const QUESTION_UPDATE_REQUEST = 'QUESTION_UPDATE_REQUEST';
/* End */

/* Learnosity sevices */
export const QUESTION_CREATE_SERVICE = 'QUESTION_CREATE_SERVICE';
export const QUESTION_PREVIEW_SERVICE = 'QUESTION_PREVIEW_SERVICE';
export const QUESTION_EDIT_SERVICE = 'QUESTION_EDIT_SERVICE';
/* End */

export const IMAGE_SIGNURL_REQUEST = 'IMAGE_SIGNURL_REQUEST';
export const IMAGE_UPLOAD_REQUEST = 'IMAGE_UPLOAD_REQUEST';

export const HTML_POST_MESSAGE = 'HTML_POST_MESSAGE';
export const ORIGINAL_PATH = 'original_path';
export const USER_DATA = 'user_data';

export const TEST_CONTENT_MAP_ID = '1234';
export const TEST_PROJECT_ID = '1234';

export const QUESTION_STATUS_WORKING_PROGRESS = 'wip';
export const QUESTION_TYPE_LEARNOSITY = 'learnosity';

/* Flashcard services */
export const DECK_TITLE_CREATE_REQUEST = 'DECK_TITLE_CREATE_REQUEST';
export const DECK_TITLE_UPDATE_REQUEST = 'DECK_TITLE_UPDATE_REQUEST';
export const DECK_PUBLISH_REQUEST = 'DECK_PUBLISH_REQUEST';
export const FLASHCARD_CREATE_REQUEST = 'FLASHCARD_CREATE_REQUEST';
export const FLASHCARD_UPDATE_REQUEST = 'FLASHCARD_UPDATE_REQUEST';
export const FLASHCARD_DELETE_REQUEST = 'FLASHCARD_DELETE_REQUEST';

/* GitLab feature flags */
export const TEST_FEATURE_FLAG_INTEGRATION = 'test_feature_flag_integration';
export const FEATURE_FLAG_REGULAS_API = 'feature_flag_regulas_api_integration';
export const FEATURE_FLAG_INTERACTIVES = 'feature_flag_interactives';
