/* eslint-disable prettier/prettier */
import { Box } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { Framework } from '@wiley-flint/framework';
import { useParams } from 'react-router-dom';
import { useFlag } from '@unleash/proxy-client-react';
import Create from './components/Create';
import connectImageDrawer from '../shared/hoc/connectImageDrawer';
import DialogBox from '../shared/components/DialogBox';
import { getParameterByPath } from '../../../common/utils/RouterPathUtility';
import * as constants from '../../../common/constants';

const AuthoringContainer: React.FunctionComponent = () => {
  const { language, question, modalView, unleash } = Framework.useStore();

  const className = 'learnosity-question-editor';
  const parameters: any = useParams();
  const param = getParameterByPath();

  const featureRegulasEnabled = useFlag(
    constants.FEATURE_FLAG_REGULAS_API
  );

  const featureInteractivesEnabled = useFlag(
    constants.FEATURE_FLAG_INTERACTIVES
  );

  useEffect(() => {
    // should append questionEditorAPI script
    const script = document.createElement('script');
    script.setAttribute(
      'src',
      'https://questioneditor.learnosity.com/?v2022.2.LTS'
    );
    document.head.append(script);

    script.onload = () => {
      unleash.set({isRegulasEnabled: featureRegulasEnabled}, 'isRegulasEnabled');
      unleash.set({isIntractivesEnabled: featureInteractivesEnabled}, 'isIntractivesEnabled')
      question.create(className, parameters[`${param}`]);
    };

    return () => script.remove();
  }, []);

  const onUpdate = () => {
    question.forceUpdate();
  };

  const setShow = (status) => {
    modalView.set({ show: status });
  };

  return (
    <Box>
      <Create className={className} />
      <DialogBox
        onSave={onUpdate}
        show={modalView.show}
        setShow={setShow}
        title="Warning"
        body={`<p>There’s already a question in the media library named <a href=${window.location.origin}/questions/${question.cmsId}/edit>${question.fileName}</a>. Saving will create a new version of that question.</p>`}
      />
    </Box>
  );
};

export default observer(connectImageDrawer(observer(AuthoringContainer)));
