import { BaseService } from '@wiley-flint/framework';
import * as constants from '../../../../common/constants';
import env from '../../../../common/env';

export default class PostMessageService extends BaseService {
  constructor() {
    super(constants.HTML_POST_MESSAGE, 'question');
  }

  ancestorOrigins = document.location.ancestorOrigins;

  async handleEvent(_: any, event: any) {
    let origin = '';
    if (Array.isArray(this.ancestorOrigins)) {
      // eslint-disable-next-line prefer-destructuring
      origin = this.ancestorOrigins[0];
    } else if (typeof this.ancestorOrigins === 'object') {
      origin = this.ancestorOrigins['0'];
    } else {
      origin = document.referrer;
    }

    console.log(
      JSON.stringify(
        {
          ancestorOrigins: this.ancestorOrigins,
          'document.referrer': document.referrer,
          postUrl: origin,
        },
        null,
        2
      )
    );
    window.parent.postMessage(event.payload, origin);
  }
}
