import QuestionEditor from './QuestionEditor';
import * as costants from '../../../../../common/constants';

const initConfig = {
  image: {
    src: null,
  },
  question_type_templates: {
    mcq: [
      {
        name: 'MCQ - Custom Style',
        reference: 'customMCQ',
        group_reference: 'mcq',
        description:
          'Multiple Choice question with block style and predefined options.',
        defaults: {
          options: [
            {
              label: 'Dublin',
              value: '1',
            },
            {
              label: 'Bristol',
              value: '2',
            },
            {
              label: 'Liverpool',
              value: '3',
            },
            {
              label: 'London',
              value: '4',
            },
          ],
        },
      },
    ],
  },
  ui: {
    source_button: false,
    layout: {
      validate_question_button: true,
      global_template: 'edit',
      responsive_edit_mode: {
        breakpoint: 800,
      },
    },
  },
};

export default class Create extends QuestionEditor {
  constructor() {
    super(costants.QUESTION_CREATE_SERVICE);
  }

  async handleEvent(_: any, event: any) {
    super.load(event.id, {
      image: {
        src: 'wonderla',
        width: 0,
        height: 0,
      },
      possible_responses: ['Choice A', 'Choice B', 'Choice C'],
      response_containers: [
        {
          x: 11.56,
          y: 24.33,
          width: '27.52%',
          height: '13.06%',
          aria_label: '',
        },
        {
          x: 22.57,
          y: 44.81,
          width: '27.52%',
          height: '13.06%',
          aria_label: '',
        },
        {
          x: 39.63,
          y: 67.66,
          width: '27.52%',
          height: '13.06%',
          aria_label: '',
        },
      ],
      stimulus: '<p>[This is the stem.]</p>',
      type: 'imageclozeassociationV2',
      case_sensitive: true,
      validation: {
        scoring_type: 'exactMatch',
        valid_response: {
          score: 1,
          value: [[], [], []],
        },
      },
    });
  }
}
