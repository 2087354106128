import { Framework } from '@wiley-flint/framework';
import * as constants from '../../../../common/constants';
import QueryGetContent from './graphql/QueryGetContent';
import GraphqlBaseService from './GraphqlBaseService';
import Toast from '../../../../common/utils/toastUtility';
import { getQuestion } from './QuestionAPIService/RegulasQuestionApiService';

export default class QuestionGetService extends GraphqlBaseService {
  constructor() {
    super(constants.QUESTION_VIEW_REQUEST, 'question');
  }

  async handleEvent(_, event: any) {
    const isRegulasEnabled = true;
    // const path = window.location.pathname;
    const { cmsId, followedByService } = event;
    // const type = path === `/questions/${cmsId}/preview` ? 'published' : 'wip';
    const type = 'wip';
    if (isRegulasEnabled) {
      document.body.classList.add('loading-indicator');
      getQuestion(cmsId, type)
        .then((response) => {
          document.body.classList.remove('loading-indicator');
          const { data } = response;
          if (data && data.provider !== 'learnosity') {
            throw new Error('This question type cannot be edited.');
          }
          const content =
            typeof data.content === 'string'
              ? data.content
              : JSON.stringify(data.content);
          const question = {
            cmsId: data.id,
            fileName: data.title,
            projectId: data.groupId,
            content,
          };
          this.getStore('question').set(question);
          if (followedByService) {
            Framework.sendEvent(followedByService, {
              id: cmsId,
              content,
              fileName: question.fileName,
            });
          }
        })
        .catch((error) => {
          if (error.response) {
            const occurredError = error.response.data;
            Toast.error(
              `Failed to load (${
                occurredError.message || 'Internal server error'
              }). Report the error to your project contact.`
            );
          } else {
            Toast.error(error.toString());
          }
        });
    } else {
      console.log(
        `Receiving a event at Flint ${JSON.stringify(event, null, 2)}`
      );
      console.log(`GraphQL Get Content query for CMS ID: ${cmsId}`);
      const response: any = await this.query(
        {
          query: QueryGetContent,
          variables: {
            cmsId,
          },
        },
        (error) => {
          const occurredError = error?.graphQLErrors
            ? error?.graphQLErrors[0]
            : null;

          try {
            const { message } = occurredError;
            Toast.error(
              `Failed to load (${
                message || 'Internal server error'
              }). Report the error to your project contact.`
            );
          } catch (err) {
            Toast.error(
              'Failed to load (Internal server error). Report the error to your project contact.'
            );
            console.log('Error message is not found', err);
          }
        }
      );

      this.getStore('question').set(response?.data?.getContentById);
      if (followedByService) {
        Framework.sendEvent(followedByService, {
          id: cmsId,
          content: JSON.parse(response?.data?.getContentById?.content),
          fileName: response?.data?.getContentById?.fileName,
        });
      }
    }
  }
}
