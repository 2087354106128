import axios from 'axios';
import env from '../../../../common/env';

const headers = {
  'x-api-key': env.REGULAS_CLIENT_SECRET,
  'Content-Type': 'application/json',
};

export const insertDeck = async (deckTitle: string) => {
  try {
    const response = await axios.post(
      `${env.REGULAS_BASE_URL}/poc/decks`,
      { title: deckTitle },
      {
        headers,
      }
    );
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log('error message: ', error.message);
    } else {
      console.log('unexpected error: ', error);
    }
    return error.message;
  }
};

export const updateDeckById = async (deckId: string, newDeckTitle: string) => {
  try {
    const response = await axios.put(
      `${env.REGULAS_BASE_URL}/poc/decks/${deckId}`,
      { title: newDeckTitle },
      {
        headers,
      }
    );
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log('error message: ', error.message);
    } else {
      console.log('unexpected error: ', error);
    }
    return error.message;
  }
};

export const insertFlashcard = async (
  deckId: string,
  cardFrontText: string,
  cardBackText: string
) => {
  try {
    const response = await axios.post(
      `${env.REGULAS_BASE_URL}/poc/decks/${deckId}/flashcards`,
      { frontText: cardFrontText, backText: cardBackText },
      {
        headers,
      }
    );
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log('error message: ', error.message);
    } else {
      console.log('unexpected error: ', error);
    }
    return error.message;
  }
};

export const updateFlashcard = async (
  deckId: string,
  cardId: string,
  cardFrontText: string,
  cardBackText: string
) => {
  try {
    const response = await axios.put(
      `${env.REGULAS_BASE_URL}/poc/decks/${deckId}/flashcards/${cardId}`,
      { frontText: cardFrontText, backText: cardBackText },
      {
        headers,
      }
    );
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log('error message: ', error.message);
    } else {
      console.log('unexpected error: ', error);
    }
    return error.message;
  }
};

export const publishDeck = async (cardDeckId: string) => {
  try {
    const response = await axios.put(
      `${env.REGULAS_BASE_URL}/poc/decks/${cardDeckId}/publish`,
      null,
      {
        headers,
      }
    );
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log('error message: ', error.message);
    } else {
      console.log('unexpected error: ', error);
    }
    return error.message;
  }
};

export const deleteFlashcard = async (deckId: string, cardId: string) => {
  try {
    const response = await axios.delete(
      `${env.REGULAS_BASE_URL}/poc/decks/${deckId}/flashcards/${cardId}`,
      {
        headers,
      }
    );
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log('error message: ', error.message);
    } else {
      console.log('unexpected error: ', error);
    }
    return error.message;
  }
};
