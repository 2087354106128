const questionMapper = (question: any) => {
  const value: any = {};
  if (question.cmsId !== undefined) value.cmsId = question.cmsId;
  if (question.fileName !== undefined) value.fileName = question.fileName;
  if (question.content !== undefined) value.content = question.content;
  if (question.projectId) {
    value.projectId = question.projectId;
  }
  if (question.selectedImage !== undefined)
    value.selectedImage = question.selectedImage;
  return value;
};

export default questionMapper;
