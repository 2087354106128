import gql from 'graphql-tag';

export default gql`
  mutation UpdateContent($content: AWSJSON, $cmsId: String!) {
    updateContent(newContent: { content: $content, cmsId: $cmsId }) {
      cmsId
      content
      fileName
    }
  }
`;
