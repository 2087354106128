import gql from 'graphql-tag';

export default gql`
  mutation SigningContent(
    $content: AWSJSON!
    $apiType: String!
    $domain: String
    $userId: String
  ) {
    signingContent(
      input: {
        content: $content
        apiType: $apiType
        domain: $domain
        userId: $userId
      }
    ) {
      content
    }
  }
`;
