import React from 'react';
import Button from '@mui/material/Button';

const FillImage: React.FunctionComponent = () => {
  return (
    <Button sx={{ borderRadius: 8, mt: 2, mb: 2 }} variant="contained">
      Select Fill Image
    </Button>
  );
};

export default FillImage;
