import Deck from '../models/Deck';

const DeckStore = {
  name: 'deck',
  spec: Deck,
  saveLocally: false,
  initialState: {},
};

export default [DeckStore];
