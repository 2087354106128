import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import FlashcardCreateScreen from './FlashcardCreateScreen';

const useStyles = makeStyles((theme: Theme) => ({
  wrapperBox: {
    alignItems: 'center',
  },
  toolbarStack: {
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    justifyContent: 'space-between',
  },
  changeTypeButton: {
    height: '32px',
    marginTop: 0,
    padding: theme.spacing(0.01, (1 / 8) * 5),
    border: '1px solid',
    color: theme.palette.text.primary,
    minWidth: '32px',
    borderRadius: '2px',
  },
  bottomButtonsStack: {
    paddingTop: theme.spacing(3),
  },
  cardStack: {
    paddingTop: theme.spacing(4),
    justifyContent: 'center',
  },
}));

const Flashcard: React.FunctionComponent = () => {
  const [toggle, setToggle] = useState<string | null>('edit');
  const [displayToggle, setDisplayToggle] = useState(false);
  const classes = useStyles();

  const handleToggle = (
    event: React.MouseEvent<HTMLElement>,
    newToggle: string
  ) => {
    if (newToggle) {
      setToggle(newToggle);
    }
  };

  const handleDisplayToggle = (display: boolean) => {
    setDisplayToggle(display);
  };

  return (
    <Box className={classes.wrapperBox}>
      <Stack className={classes.toolbarStack} direction="row" spacing={1}>
        <Stack direction="row" spacing={1}>
          <Button
            className={classes.changeTypeButton}
            variant="outlined"
            href={`${document.referrer
              .replace(/^[^:]+:\/\/[^/]+/, '')
              .replace(/#.*/, '')}`}
          >
            <Tooltip
              title="Change Type"
              placement="bottom-end"
              arrow
              TransitionComponent={Zoom}
            >
              <MenuOpenIcon fontSize="small" />
            </Tooltip>
          </Button>
          <Typography variant="h5" component="h5">
            Flashcards
          </Typography>
        </Stack>
        {displayToggle && (
          <Stack direction="row">
            <ToggleButtonGroup
              size="small"
              value={toggle}
              exclusive
              onChange={handleToggle}
              aria-label="text alignment"
            >
              <ToggleButton value="edit" aria-label="toggle-edit">
                Edit
              </ToggleButton>
              <ToggleButton value="preview" aria-label="toggle-preview">
                Preview
              </ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        )}
      </Stack>
      <Divider orientation="horizontal" flexItem />
      <Stack direction="row" spacing={3} className={classes.cardStack}>
        <FlashcardCreateScreen
          onDisplayToggle={handleDisplayToggle}
          toggle={toggle}
        />
      </Stack>
    </Box>
  );
};

export default Flashcard;
