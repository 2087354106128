import { applySnapshot, types, cast } from 'mobx-state-tree';

type ModalViewData = {
  show: boolean;
};

const ModalView = types
  .model('ModalView', {
    show: types.boolean,
  })
  .actions((self) => ({
    set(data: ModalViewData) {
      if (data) {
        self.show = cast(true);
        applySnapshot(self, { ...data });
      }
    },
  }))
  .views((self) => ({
    get showStatus() {
      return self.show;
    },
  }));

export default ModalView;
