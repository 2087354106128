import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  from,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { User } from 'oidc-client-ts';

import env from './env';
import ErrorHandler from './handlers/errorHandler';

function getUser() {
  const oidcStorage = sessionStorage.getItem(
    `oidc.user:${env.OIDC_AUTH}:${env.OIDC_CLIENT_ID}`
  );
  if (!oidcStorage) {
    return null;
  }

  return User.fromStorageString(oidcStorage);
}

class Client {
  graphQlClient: ApolloClient<NormalizedCacheObject>;

  constructor() {
    this.graphQlClient = new ApolloClient({
      link: from([this.errorLink, this.httpHeaderLink, this.httpProximaLink]),
      cache: new InMemoryCache(),
    });
  }

  apolloClient = () => {
    return this.graphQlClient;
  };

  addErrorHandler = (handler?: any) => {
    if (handler) {
      this.graphQlClient.setLink(
        from([onError(handler), this.httpHeaderLink, this.httpProximaLink])
      );
    }
    return this;
  };

  httpProximaLink = createHttpLink({
    uri: env.PROXIMA_BASE_URL,
  });

  httpHeaderLink = new ApolloLink((operation, forward) => {
    const user = getUser();
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        authorization: user?.access_token,
      },
    }));

    return forward(operation);
  });

  errorLink = onError((error: any) => {
    const errorHandler = new ErrorHandler(error);
    errorHandler.display();
  });
}

export const apiClient = new Client();
// TODO: Flint client needs to support ApolloClient

export default [];
