import React, { useEffect, useState } from 'react';
import { Framework } from '@wiley-flint/framework';
import { observer } from 'mobx-react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AuthoringCard from './AuthoringCard';
import * as constants from '../../../../common/constants';
import Toast from '../../../../common/utils/toastUtility';

interface FlashcardListProps {
  onPreviousClick: () => void;
  continueClicked: boolean;
}

interface CardOnSaveArgs {
  id: number;
  actualId: string;
  term: string;
  frontType: string;
  definition: string;
  backType: string;
}

const FlashcardList: React.FunctionComponent<FlashcardListProps> = ({
  onPreviousClick,
  continueClicked,
}) => {
  const { deck } = Framework.useStore();

  const [activeCardId, setActiveCardId] = useState(-1);
  const [preActiveCardId, setPreActiveCardId] = useState(-1);
  const [data, setData] = useState([]);
  const [save, setSave] = useState(false);
  const [filledCardCount, setFilledCardCount] = useState(0);

  const emptyCardData = {
    actualId: '',
    term: '',
    frontType: 'Text',
    definition: '',
    backType: 'Text',
    saved: false,
  };

  const getFilledCardCount = () => {
    let filledCount = 0;
    deck.flashcards.forEach((item) => {
      const { term, definition } = item;
      if (term?.trim() !== '' && definition?.trim() !== '') {
        filledCount += 1;
      }
    });
    setFilledCardCount(filledCount);
  };

  const handleOnCardSave = ({
    id,
    actualId,
    term,
    frontType,
    definition,
    backType,
  }: CardOnSaveArgs) => {
    const cardDataInDeck = deck.flashcards.filter((item) => item.id === id)[0];
    const cardDeckId = deck.flashcardDeckId;
    const cardData = {
      id,
      actualId,
      term,
      frontType,
      definition,
      backType,
      cardDeckId,
    };
    if (cardDataInDeck && cardDataInDeck.actualId) {
      if (!cardDataInDeck.saved) {
        deck.updateSavedCard(cardData);
      }
    } else {
      deck.saveCard(cardData);
    }
  };

  const handleCardDelete = (cardId: number) => {
    const deckId = deck.flashcardDeckId;
    const { actualId } = deck.flashcards.filter(
      (item) => item.id === cardId
    )[0];
    if (actualId) {
      deck.deleteCard(deckId, actualId, cardId);
    } else {
      const deletedCardId = deck.updateDeckAfterDeletingCard(cardId);
      if (deletedCardId) {
        Toast.success('Unsaved card deleted');
      } else {
        Toast.error('Failed to delete the unsaved card');
      }
    }
  };

  const handleDataChange = (id: number, cardData: any) => {
    const { actualId, term, frontType, definition, backType } = cardData;
    const cardPresent = deck.flashcards.filter((item) => item.id === id)[0];
    if (
      cardPresent &&
      (term !== cardPresent.term || definition !== cardPresent.definition)
    ) {
      const updateCardData = {
        id,
        actualId,
        term,
        frontType,
        definition,
        backType,
        saved: false,
      };
      deck.updateCard(updateCardData);
    }
  };

  const checkUnsavedCards = () => {
    const cardDeckId = deck.flashcardDeckId;
    deck.flashcards.forEach((item) => {
      const { id, actualId, term, frontType, definition, backType, saved } =
        item;
      if (!saved && term !== '' && definition !== '') {
        const cardDataToSave = {
          id,
          actualId,
          term,
          frontType,
          definition,
          backType,
        };
        handleOnCardSave(cardDataToSave);
      }
    });
    return cardDeckId;
  };

  const handleCardSave = () => {
    setSave(true);
    const deckId = checkUnsavedCards();
    Framework.sendEvent(constants.DECK_PUBLISH_REQUEST, {
      cardDeckId: deckId,
    });
  };

  const handlePreviousButtonClick = () => {
    const deckId = checkUnsavedCards();
    if (deckId) {
      onPreviousClick();
    }
  };

  const handleCardActive = (id: number) => {
    setPreActiveCardId(activeCardId);
    setActiveCardId(id);
  };

  useEffect(() => {
    if (deck.publishedVersionId) {
      setSave(false);
    }
    return () => {
      onPreviousClick();
      deck.setPublishedVersion('');
    };
  }, [deck.publishedVersionId]);

  useEffect(() => {
    if (continueClicked) {
      setData(deck.flashcards);
    }
  }, [continueClicked]);

  useEffect(() => {
    getFilledCardCount();
    setData(deck.flashcards);
  }, [deck.flashcards]);

  useEffect(() => {
    if (filledCardCount === deck.flashcards.length - 2) {
      const lastCardId = deck.flashcards[deck.flashcards.length - 1]?.id;
      deck.addCards({ id: lastCardId + 1, ...emptyCardData });
      deck.addCards({ id: lastCardId + 2, ...emptyCardData });
    }
  }, [filledCardCount]);

  return (
    <Container sx={{ width: 800 }}>
      <Stack
        direction="row"
        sx={{ justifyContent: 'space-between', marginLeft: 8, marginBottom: 1 }}
      >
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={handlePreviousButtonClick}
        >
          back
        </Button>
      </Stack>
      <Stack sx={{ marginLeft: 8 }} direction="column" spacing={2}>
        {data.map((item, index) => {
          const { id, actualId, term, frontType, definition, backType } = item;
          return (
            <Box key={id.toString()}>
              <AuthoringCard
                index={index + 1}
                id={id}
                termText={frontType === 'Text' && term}
                frontSelectType={frontType}
                definitionText={backType === 'Text' && definition}
                backSelectType={backType}
                save={save}
                actualId={actualId}
                prevActiveCardId={preActiveCardId}
                onDataChange={handleDataChange}
                onCardDelete={handleCardDelete}
                onCardActive={handleCardActive}
                onCardSave={handleOnCardSave}
              />
            </Box>
          );
        })}
      </Stack>
      <Stack
        direction="row"
        spacing={1}
        sx={{ justifyContent: 'center', mt: 4 }}
      >
        <Button
          sx={{ borderRadius: 8 }}
          variant="contained"
          onClick={handleCardSave}
        >
          save
        </Button>
      </Stack>
    </Container>
  );
};

export default observer(FlashcardList);
