import React from 'react';
import ReactDOM from 'react-dom';
import QuestionFileName from './components/QuestionFileName';

interface QuestionFileNameError {
  status: boolean;
  message: string;
}

interface Handlers {
  error?: QuestionFileNameError;
  onValueChange: (value: string) => void;
  questionFileName?: string;
}

const applyQuestionFileName = ({
  onValueChange,
  error,
  questionFileName,
}: Handlers) => {
  const parentDiv = document.querySelector('.lrn-qe-toolbar');
  if (!document.querySelector('#question-title')) {
    const div = document.createElement('div');
    div.id = 'question-title';
    parentDiv.after(div);
  }

  ReactDOM.render(
    <QuestionFileName
      onValueChange={onValueChange}
      error={error}
      questionFileName={questionFileName}
    />,
    document.getElementById('question-title')
  );
};

export default applyQuestionFileName;
