import React, { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { buildApp, Framework } from '@wiley-flint/framework';
import { useUnleashContext } from '@unleash/proxy-client-react';
import modules from './modules';
import clients from './common/clients';
import i18n from './i18n';
import './App.css';
import NotFoundContainer from './common/NotFoundContainer';
import AlertComponent from './common/components/alerts/AlertComponent';
import Redirect from './Redirect';

const intlBuilder = Framework.getIntlBuilder();

// eslint-disable-next-line no-restricted-syntax
for (const [language, value] of Object.entries(i18n)) {
  intlBuilder.add(language, value);
}

intlBuilder.setDefaultLang('en').addPseudoLocale('pt').build();
const Comp = buildApp('stg', clients, modules);

const AssessmentApp: React.FunctionComponent = () => {
  const auth = useAuth();
  const updateContext = useUnleashContext();

  useEffect(() => {
    return auth.events.addAccessTokenExpiring(() => {
      // silently renew tokens
      auth.signinSilent();
    });
  }, [auth.events, auth.signinSilent]);

  useEffect(() => {
    if (auth.user) updateContext({ userId: auth.user.profile.sub });
  }, [auth]);

  if (auth.isLoading) {
    return <div>Loading...</div>;
  }
  if (auth.error) {
    return <div>Oops... {auth.error.message}</div>;
  }
  if (auth.isAuthenticated) {
    return (
      <ThemeProvider theme={createTheme({})}>
        <AlertComponent />
        <Comp requestIdPrefix="ENZO" notFound={NotFoundContainer} />
      </ThemeProvider>
    );
  }
  return <Redirect />;
};

export default AssessmentApp;
