import env from './env';

const FeatureFlagConfig = {
  url: env.GITLAB_PROXY_URL,
  clientKey: env.GITLAB_PROXY_CLIENT_KEY,
  refreshInterval: env.GITLAB_PROXY_REFRESH_INTERVAL,
  environment: env.GITLAB_PROXY_ENVIRONMENT,
  appName: env.GITLAB_PROXY_APP_NAME,
};

export default FeatureFlagConfig;
