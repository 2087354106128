const en = {
  'common.SEARCH': 'Search',
  'footer.COPYRIGHT': '(c) 2021 John Wiley and sons. All rights reserved.',
  'authoring.WELCOME_TITLE': 'Welcome to Assessment Authoring',
  'question.WELCOME_TITLE': 'Question!',
  'common.NOTFOUND_TITLE': 'Not Found',
  'common.NOTFOUND_DESC': 'The page you are looking for is not found.',
};

export default en;
