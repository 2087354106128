import React, { useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Framework } from '@wiley-flint/framework';
import Dialog from '@mui/material/Dialog';
import FileDrawer from '../components/FileDrawer';
import env from '../../../../common/env';

// TODO: questions reference in the HOC should be passed as a parameter to HOC

const connectImageDrawer =
  (Component: React.FC) =>
  ({ ...props }) => {
    const { question } = Framework.useStore();
    const params = useParams<{ projectId: string }>();
    const projectIdForCreate = params.projectId;

    const projectId = projectIdForCreate || question.projectId;

    const handleAltTextDialogClose = () => {
      question.selectedImage.setEditing(false);
      question.selectedImage.setUploading(false);
      question.selectedImage.close();
    };

    const onAltTextChange = (value: string) => {
      question.selectedImage.setImageAltText(value);
    };

    const handleClose = () => {
      question.selectedImage.setEditing(false);
      question.selectedImage.setUploading(false);
      question.selectedImage.close();
    };

    const handleSaveClick = () => {
      question.selectedImage.update({ editing: false, uploading: false });
      question.selectedImage.close();
    };

    const handleUpdateClick = () => {
      question.selectedImage.update({
        editing: false,
        uploading: true,
      });
      handleClose();
    };

    const handleMessage = useCallback((event: MessageEvent) => {
      if (event.origin === env.AQUILA_URL) {
        const { data } = event;
        if (data && data.action === 'cancel') {
          handleClose();
        } else if (data && data.length > 0) {
          // eslint-disable-next-line no-underscore-dangle
          const imageURL = data[0]._links.SourceRendition.href;
          question.selectedImage.setEditing(true);
          question.selectedImage.setUploading(false);
          question.selectedImage.setUrl(imageURL);
        }
      }
    }, []);

    useEffect(() => {
      window.addEventListener('message', handleMessage);
      return () => {
        window.removeEventListener('message', handleMessage);
      };
    }, []);

    const url = `${env.AQUILA_URL}/projects/${projectId}/mediaselect?mediaType=Image`;
    return (
      <div>
        <Dialog
          open={
            question.selectedImage.uploading || question.selectedImage.editing
          }
          onClose={handleClose}
          fullScreen
        >
          {question.selectedImage.uploading && (
            <iframe title="Select Image" src={url} style={{ height: '100%' }} />
          )}
          {question.selectedImage.editing && (
            <FileDrawer
              updateFlow={question.selectedImage?.updateFlow}
              onClose={handleAltTextDialogClose}
              onSaveClick={handleSaveClick}
              onUpdateClick={handleUpdateClick}
              title="Image Alt Text"
              altText={question.selectedImage?.altText}
              onAltTextChange={onAltTextChange}
              imageUrl={question.selectedImage?.metadata.url}
            />
          )}
        </Dialog>
        <Component {...props} />
      </div>
    );
  };

export default connectImageDrawer;
