import { BaseService } from '@wiley-flint/framework';
import anylogger from 'anylogger';
import { apiClient } from '../../../../common/clients';
import './loader.css';

const log = anylogger('GraphqlBaseService');

export default class GraphqlBaseService extends BaseService {
  // TODO: Error handling

  // eslint-disable-next-line no-useless-constructor
  constructor(request: string, store: string) {
    super(request, store);
  }

  // eslint-disable-next-line class-methods-use-this
  get client() {
    return apiClient;
  }

  mutate = (graphql: any, errorHandler?: (error: any) => void) => {
    return new Promise((resolve) => {
      document.body.classList.add('loading-indicator');
      this.client
        .addErrorHandler(errorHandler)
        .apolloClient()
        .mutate(graphql)
        .then((response: unknown) => resolve(response))
        .catch((error) => log.error(error))
        .then(() => {
          document.body.classList.remove('loading-indicator');
        });
    });
  };

  query = (graphql: any, errorHandler?: (error: any) => void) => {
    document.body.classList.add('loading-indicator');
    return new Promise((resolve) => {
      document.body.classList.add('loading-indicator');
      this.client
        .addErrorHandler(errorHandler)
        .apolloClient()
        .query(graphql)
        .then((response: any) => resolve(response))
        .catch((error) => log.error(error))
        .then(() => {
          document.body.classList.remove('loading-indicator');
        });
    });
  };

  queryMany = (graphqls: Array<any>) => {
    return Promise.all(
      graphqls.map((graphql: any) => this.client.apolloClient().query(graphql))
    );
  };
}
