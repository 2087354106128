import { AlertColor } from '@mui/material';
import { cast, types } from 'mobx-state-tree';

export type AlertTypes = AlertColor;
export interface AlertProps {
  message: string;
  type: AlertTypes;
  description?: {
    message: string;
    cmsId: string;
  };
}

const initialDescriptionModelValues = {
  message: '',
  cmsId: '',
};

// isRead should be true because in AlertComponent trying to alert message if !isRead
const initialAlertStoreValues = {
  message: '',
  type: '',
  description: initialDescriptionModelValues,
  isRead: true,
};

export const AlertModel = types.model({
  message: types.string,
  type: types.string,
  description: types.optional(
    types.model({
      message: types.string,
      cmsId: types.string,
    }),
    initialDescriptionModelValues
  ),
  isRead: false,
});

const AlertStore = types
  .model({
    alert: types.optional(AlertModel, initialAlertStoreValues),
  })
  .actions((self) => ({
    set(alert: AlertProps) {
      self.alert = cast({ ...alert, isRead: false });
    },
    onClose() {
      self.alert.isRead = true;
    },
  }));

export const alertStore = AlertStore.create();
