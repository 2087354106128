const uat = {
  STAGE: 'uat',
  OIDC_CLIENT_ID: 'f8fabfa4-dd14-4911-ad69-cfffa6d6f926',
  OIDC_CLIENT_SECRET: 'ea39b0af-8cd0-486b-9097-1c387c19e449',
  MEF_ORIGIN: 'https://wiley.preprodmef.io/',
  REGULAS_BASE_URL:
    'https://klakkyi5if.execute-api.us-east-1.amazonaws.com/uat',
  REGULAS_CLIENT_SECRET: 'SlLehJJfbi4bWk9CCuNQL6traidbO3VlaH5QeOpS',
  GITLAB_PROXY_URL:
    'https://217f5gbqr8.execute-api.us-east-1.amazonaws.com/uat/proxy',
  GITLAB_PROXY_ENVIRONMENT: 'uat',
  AQUILA_URL: 'https://uat.aquila.wiley.host',
};

export default uat;
