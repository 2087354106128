import { Framework } from '@wiley-flint/framework';
import { applySnapshot, types, cast } from 'mobx-state-tree';
import * as constants from '../../../common/constants';

type DeckCardDelegate = {
  id: number;
  actualId: string;
  term: string;
  frontType: string;
  definition: string;
  backType: string;
  saved: boolean;
};

type CardServiceDelegate = {
  id: number;
  actualId: string;
  term: string;
  frontType: string;
  definition: string;
  backType: string;
  cardDeckId: string;
};

const renderInitialCards = () => {
  const initialCardData = <DeckCardDelegate[]>[];
  [...Array(10)].forEach((item, index) => {
    initialCardData.push({
      id: index + 1,
      actualId: '',
      term: '',
      frontType: 'Text',
      definition: '',
      backType: 'Text',
      saved: false,
    });
  });
  return initialCardData;
};

const CardArray = types
  .model('cardArray', {
    id: types.maybeNull(types.number),
    actualId: types.maybeNull(types.string),
    term: types.maybeNull(types.string),
    frontType: types.maybeNull(types.string),
    definition: types.maybeNull(types.string),
    backType: types.maybeNull(types.string),
    saved: types.maybeNull(types.boolean),
  })
  .actions((self) => ({
    update(newData) {
      applySnapshot(self, newData);
    },
  }));

const DeckModel = {
  deckId: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  cards: types.optional(types.array(CardArray), renderInitialCards),
  publish: types.maybeNull(types.boolean),
  publishedVersion: types.maybeNull(types.string),
};

const Deck = types
  .model('deck', DeckModel)
  .actions((self) => ({
    create(title: string) {
      const applySnapShotModel = { ...self };
      applySnapshot(self, { ...applySnapShotModel });
      Framework.sendEvent(constants.DECK_TITLE_CREATE_REQUEST, { title });
    },
    update(deckId: string, title: string) {
      applySnapshot(self, { ...self, title, deckId });
      Framework.sendEvent(constants.DECK_TITLE_UPDATE_REQUEST, {
        deckId,
        title,
      });
    },
    setPublish(publish: boolean) {
      self.publish = publish;
    },
    setPublishedVersion(publishedVersion: string) {
      applySnapshot(self, { ...self, publishedVersion });
    },
    setTitle(title: string) {
      applySnapshot(self, { ...self, title });
    },
    setDeckId(deckId: string) {
      applySnapshot(self, { ...self, deckId });
    },
    updateCard(card: DeckCardDelegate) {
      const filteredCards = self.cards.filter((item) => item.id !== card.id);
      filteredCards.push(cast(card));
      self.cards = cast(filteredCards);
    },
    addCards(card) {
      self.cards.push(card);
    },
    saveCard(saveCardData: CardServiceDelegate) {
      Framework.sendEvent(constants.FLASHCARD_CREATE_REQUEST, {
        ...saveCardData,
      });
    },
    updateSavedCard(updateCardData: CardServiceDelegate) {
      Framework.sendEvent(constants.FLASHCARD_UPDATE_REQUEST, {
        ...updateCardData,
      });
    },
    deleteCard(deckId: string, cardId: string, id: number) {
      Framework.sendEvent(constants.FLASHCARD_DELETE_REQUEST, {
        deckId,
        cardId,
        id,
      });
    },
    updateDeckAfterDeletingCard(cardId) {
      const filteredCards = self.cards.filter((item) => item.id !== cardId);
      self.cards = cast(filteredCards);
      return cardId;
    },
    resetDeck() {
      self.deckId = '';
      self.title = '';
      self.cards = cast(renderInitialCards());
      self.publish = false;
    },
  }))
  .views((self) => ({
    get publishStatus() {
      return self.publish;
    },
    get publishedVersionId() {
      return self.publishedVersion;
    },
    get flashcardDeckId() {
      return self.deckId;
    },
    get flashcardDeckTitle() {
      return self.title;
    },
    get flashcards() {
      const deckStoreData = {
        cards: self.cards
          .slice()
          .sort((a, b) => (a.id > b.id ? 1 : -1))
          .map((item, index) => {
            return {
              id: item.id,
              actualId: item.actualId,
              term: item.term,
              frontType: item.frontType,
              definition: item.definition,
              backType: item.backType,
              saved: item.saved,
            };
          }),
      };
      return [...deckStoreData.cards];
    },
    get getDeckCardIds() {
      const deckStoreData = {
        cards: self.cards.slice().map((item) => {
          return {
            id: item.id,
            actualId: item.actualId,
          };
        }),
      };
      return [...deckStoreData.cards];
    },
  }));

export default Deck;
