import React from 'react';

import PreviewLearnosity from './PreviewLearnosity';
import PreviewCustom from './PreviewCustom';

interface PreviewProps {
  id: string;
  content: any;
}
const Preview: React.FunctionComponent<PreviewProps> = ({ id, content }) => {
  return content.type === 'custom' ? (
    <PreviewCustom id={id} content={content} />
  ) : (
    <PreviewLearnosity id={id} content={content} />
  );
};

export default Preview;
