import React from 'react';
import { AuthProvider } from 'react-oidc-context';
import withFeatureFlag from './common/hoc/withFeatureFlag';

import './App.css';
import AssessmentApp from './AssessmentApp';

import env from './common/env';

const oidcConfig = {
  // onBeforeSignIn: () => {
  //   // TODO: Revisit here for follow up implementation
  //   if (
  //     location.pathname !== '/refreshLogin' &&
  //     location.pathname !== '/signin-oidc'
  //   ) {
  //     persitantStore.setOrginalPath(location.pathname);
  //   }
  // },
  // onSignIn: () => {
  //   const path: string = persitantStore.getOrginalPath();
  //   // eslint-disable-next-line no-unused-expressions
  //   path ? Framework.redirect(path) : Framework.redirect('/questions/create');
  // },
  // automaticSilentRenew: true,
  authority: env.OIDC_AUTH,
  client_id: env.OIDC_CLIENT_ID,
  redirect_uri: env.OIDC_REDIRECT_URI,
  response_type: 'code',
  scopes: 'openid',
  client_secret: env.OIDC_CLIENT_SECRET,
};

const App: React.FunctionComponent = () => {
  return (
    <AuthProvider {...oidcConfig}>
      <AssessmentApp />
    </AuthProvider>
  );
};

export default withFeatureFlag(App);
