import React from 'react';

interface CreateProps {
  className: string;
}
const Create: React.FunctionComponent<CreateProps> = ({ className }) => {
  return (
    <div
      id="questionCreate"
      data-testid="question-create"
      className={className}
    />
  );
};

export default Create;
