import React from 'react';
import { observer } from 'mobx-react-lite';
import { FlagProvider } from '@unleash/proxy-client-react';
import FeatureFlagConfig from '../FeatureFlagConfig';

const withFeatureFlag = function _withFeatureFlag<T>(
  Component: React.ComponentType<T>
) {
  const displayName = Component.displayName || Component.name || 'Component';
  const ComponentWithFeatureFlag = (props: T) => {
    return (
      <FlagProvider config={FeatureFlagConfig}>
        <Component {...(props as T)} />
      </FlagProvider>
    );
  };
  ComponentWithFeatureFlag.displayName = `withFeatureFlag(${displayName})`;
  return observer(ComponentWithFeatureFlag);
};

export default withFeatureFlag;
