import React from 'react';
import Box from '@mui/material/Box';
import { observer } from 'mobx-react';
import Flashcard from './components/Flashcard';
import connectImageDrawer from '../../Questions/shared/hoc/connectImageDrawer';

const FlashcardContainer: React.FunctionComponent = () => {
  return (
    <Box>
      <Flashcard />
    </Box>
  );
};

export default observer(connectImageDrawer(FlashcardContainer));
