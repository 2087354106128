import { BaseService } from '@wiley-flint/framework';
import anylogger from 'anylogger';
import axios from 'axios';
import * as constants from '../../../../../common/constants';

const log = anylogger('GraphqlBaseService');

export default class SignRequest extends BaseService {
  constructor() {
    super(constants.IMAGE_UPLOAD_REQUEST, 'question');
  }

  async handleEvent(_: any, event: any) {
    log.debug(this.getStore('question'));
    const { metadata, file } = this.getStore('question').selectedImage;
    const options = {
      headers: {
        'Content-Type': metadata?.fileType,
      },
    };
    await axios.put(event.signedRequest, file, options);
    this.getStore('question').selectedImage.update({
      editing: false,
      uploading: false,
    });
  }
}
