const prod = {
  STAGE: 'prod',
  OIDC_CLIENT_ID: 'f948a2e3-a8c0-4831-8628-72e340fadf29',
  OIDC_CLIENT_SECRET: '038d98bd-a928-492a-af1f-6e9f769ef96e',
  OIDC_AUTH: 'https://external-sso.wiley.com/auth/realms/wiley',
  MEF_ORIGIN: 'https://wiley.mef.io',
  GITLAB_PROXY_URL:
    'https://ridtqc2sgc.execute-api.us-east-1.amazonaws.com/prod/proxy',
  GITLAB_PROXY_ENVIRONMENT: 'prod',
  REGULAS_BASE_URL:
    'https://8p40nzdug5.execute-api.us-east-1.amazonaws.com/prod',

  REGULAS_CLIENT_SECRET: 'iWNqD3m2KU8vCxaCcvQYR2jwxhuASUp12AJGsVV8',
  AQUILA_URL: 'https://aquila.wiley.host',
};

export default prod;
