import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Framework } from '@wiley-flint/framework';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

interface DeckNamingScreenProps {
  onContinueButtonClick: (continued: boolean) => void;
}

const DeckNamingScreen: React.FunctionComponent<DeckNamingScreenProps> = ({
  onContinueButtonClick,
}) => {
  const [deckTitle, setTitle] = useState('');
  const [titleErrorStatus, setTitleErrorStatus] = useState(false);

  const { deck } = Framework.useStore();
  const { deckId, title } = deck;

  const saveDeckTitle = () => {
    if (deckId) {
      if (title !== deckTitle.trim()) {
        deck.update(deckId, deckTitle);
      }
    } else {
      deck.create(deckTitle);
    }
  };

  const handleContinueButtonClick = () => {
    if (deckTitle.trim()) {
      saveDeckTitle();
      onContinueButtonClick(true);
    } else {
      setTitleErrorStatus(true);
    }
  };

  const handleCardTitleChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTitle(event.target.value);
  };

  useEffect(() => {
    if (deckTitle) {
      setTitleErrorStatus(false);
    }
  }, [deckTitle]);

  useEffect(() => {
    if (title) {
      setTitle(title);
    }
  }, [title]);

  return (
    <Box
      component="form"
      width={500}
      sx={{
        padding: '24px',
        border: '1px solid rgba(0, 0, 0, 0.1)',
        borderRadius: '10px',
      }}
      noValidate
      autoComplete="off"
    >
      <Stack direction="column" spacing={3}>
        <Typography variant="h6" gutterBottom>
          Name your Flashcard Set
        </Typography>
        <TextField
          error={titleErrorStatus}
          fullWidth
          label="Flashcard set name"
          id="set-name"
          variant="filled"
          value={deckTitle}
          helperText={titleErrorStatus ? 'Set name is required!' : ''}
          onChange={handleCardTitleChange}
        />
        <Stack sx={{ justifyContent: 'center' }} direction="row">
          <Button
            sx={{ borderRadius: 8 }}
            variant="contained"
            onClick={handleContinueButtonClick}
          >
            continue
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default observer(DeckNamingScreen);
