import PreviewContainer from './PreviewContainer';
import PreviewEditorUIService from '../shared/services/EditorUIService/Preview';

const QuestionPreview = {
  path: ['/questions/:cmsid/preview'],
  exact: true,
  services: [new PreviewEditorUIService()],
  stores: [],
  component: PreviewContainer,
};

export default QuestionPreview;
