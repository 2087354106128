import { BaseService } from '@wiley-flint/framework';
import * as constants from '../../../common/constants';
import Toast from '../../../common/utils/toastUtility';
import { deleteFlashcard } from './APIService/FlashcardAPI';

const CARD_DELETED_STATUS_CODE = 204;
export default class CardDeleteService extends BaseService {
  constructor() {
    super(constants.FLASHCARD_DELETE_REQUEST, 'deck');
  }

  async handleEvent(_: any, event: any) {
    const { deckId, cardId, id } = event;
    try {
      const response = await deleteFlashcard(deckId, cardId);

      if (response?.status === CARD_DELETED_STATUS_CODE) {
        this.getStore('deck').updateDeckAfterDeletingCard(id);
        Toast.success(`Card deleted successfully: Id ${cardId}`);
      } else {
        throw new Error(response);
      }
    } catch (error) {
      Toast.error(`Failed to delete the card: ${error?.message}`);
    }
  }
}
