const local = {
  STAGE: 'local',
  PROXIMA_BASE_URL: 'http://localhost:20002/graphql',
  REGULAS_BASE_URL:
    'https://19mlnrk0i0.execute-api.us-east-1.amazonaws.com/poc',
  REGULAS_CLIENT_SECRET: '5qiUsFzM0f1wNFxa8qrQ4ZBsGjfuBdDzuhyqNP80',
  GITLAB_PROXY_URL:
    'https://eom5ax8tpf.execute-api.us-east-1.amazonaws.com/dev/proxy',
  GITLAB_PROXY_ENVIRONMENT: 'dev',
};

export default local;
