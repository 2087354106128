import { Box } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import Authoring from './components/Authoring';

const AuthoringContainer: React.FunctionComponent = () => {
  return (
    <Box>
      <Authoring />
    </Box>
  );
};

export default observer(AuthoringContainer);
