const dev = {
  STAGE: 'dev',
  REGULAS_BASE_URL:
    'https://bgp0nopeja.execute-api.us-east-1.amazonaws.com/dev',
  REGULAS_CLIENT_SECRET: 'K7mQPh8o1b7Wt6h5eSrAl7jzCbOqKBPK2BdOeyXl',
  GITLAB_PROXY_URL:
    'https://fp0zc1ggyb.execute-api.us-east-1.amazonaws.com/dev/proxy',
  GITLAB_PROXY_ENVIRONMENT: 'dev',
  MEF_ORIGIN: 'https://wiley.rctmef.io/',
};

export default dev;
