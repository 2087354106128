import { Framework } from '@wiley-flint/framework';
import anylogger from 'anylogger';
import env from '../../../../../common/env';
import Toast from '../../../../../common/utils/toastUtility';
import * as constants from '../../../../../common/constants';
import QueryGetTableOfContentId from '../graphql/QueryGetTableOfContentId';
import GraphqlBaseService from '../GraphqlBaseService';

const log = anylogger('GraphqlBaseService');
const generateInitConfig = (interactivesEnabled: boolean) => {
  const initConfig = {
    configuration: { consumer_key: env.QUESTION_EDITOR_CONSUMERKEY },
    template_defaults: true,
    group_defaults: true,
    simple_feature_templates: [
      {
        reference: '4d918ac6-2ac5-48f5-8707-c1957c1572cb',
      },
      {
        reference: '116e3f78-a564-4862-877c-91cdad8a2c2b',
      },
      {
        reference: '20587eaf-76ad-4b82-bc96-7e266a72b6ff',
      },
      {
        reference: 'd3e05438-8321-457c-a541-3cb18020eae8',
      },
    ],
    question_type_groups: [
      {
        reference: 'mcq',
        template_references: [
          '9e8149bd-e4d8-4dd6-a751-1a113a4b9163',
          '3egs0b24-5gs8-49fc-fds9-4a450sdg31ca',
          '908de244-5c71-4c09-b094-7fb49554f2f9',
          '14b7af71-e146-4747-aae5-ca71618a5038',
        ],
      },
      {
        reference: 'cloze',
        template_references: [
          '2fbba51b-e35e-441f-83c7-2662e2e81fa6',
          '457fe101-0667-4a35-b193-b849653acb52',
        ],
      },
      {
        reference: 'match',
        template_references: [
          '0c7f68f7-2be6-4ae0-8492-0ab78c8d0010',
          '1fa22aac-1f88-47f7-941b-3c77759549e6',
        ],
      },
      {
        reference: 'writespeak',
        template_references: ['4fe6c726-419c-4aff-b189-4a9756a0ffda'],
      },
      { reference: 'highlight', template_references: [] },
      { reference: 'math', template_references: [] },
      { reference: 'graph', template_references: [] },
      { reference: 'chart', template_references: [] },
      { reference: 'chemistry', template_references: [] },
      ...(interactivesEnabled
        ? [
            {
              name: 'Interactives',
              reference: 'flashcards',
              group_icon:
                'https://img.icons8.com/ios-filled/50/000000/flashcards.png',
            },
          ]
        : []),
      { reference: 'other', template_references: [] },
    ],

    /*
    question_type_templates: {
      clozedropdown: [
        ...(interactivesEnabled
          ? [
              {
                name: 'Flashcards',
                reference: 'customFlashcards',
                group_reference: 'flashcards',
                type: 'custom',
                group_defaults: false,
                image:
                  'https://s3.amazonaws.com/proxima.interplay-media-store/sample_image_for_flashcard_menu_4.png',
                description: 'Custom question type for creating Flashcards',
              },
            ]
          : []),
      ],
    },
    */

    widget_type: 'response',
    ui: {
      change_button: true,
      distractor_rationale_button: {
        enabled: true,
        options: ['perQuestion', 'perResponse'],
      },
      validate_question_button: true,
    },
    user: {
      id: 'hgangadhar',
    },
  };

  return initConfig;
};
export default class Learnosity extends GraphqlBaseService {
  constructor(eventName: string) {
    super(eventName, 'question');
  }

  async load(className: string, content: any) {
    const isIntractivesEnabled = this.getStore('unleash').getStatus(
      'isIntractivesEnabled'
    );
    let questionEditorApp: any;
    const callbacks = {
      assetRequest:
        this.getStore('question').selectedImage.assetRequestFunction,
      readyListener: () => {
        this.getStore('question').loaded(this.eventName);
        this.getStore('question').update({
          content: questionEditorApp.getJson(),
        });
        // Question Editor API sucessfully loaded according to pased init options
        // we can now reliably start calling public methods and listen to events
        questionEditorApp.on('widget:ready', function (a: any, b: any) {
          // widget has changed, probably as a result of calling setWidget()
          log.debug('ready', a, b);
          // applySaveButton(() => onSucesss(questionEditorApp.getJson()));
        });
        questionEditorApp.on('input:added', (a: any, b: any) => {
          // widget has changed, probably as a result of calling setWidget()
          log.debug('input:added', a, b);
          this.getStore('question').update({
            content: questionEditorApp.getJson(),
          });
        });
        questionEditorApp.on('preview:changed', (a: any, b: any) => {
          // widget has changed, probably as a result of calling setWidget()
          log.debug('preview:changed', a, b);
          this.getStore('question').update({
            content: questionEditorApp.getJson(),
          });
          questionEditorApp.checkValidation({ showErrors: true });
          questionEditorApp.attribute('instant_feedback')?.setValue(true);
        });
      },

      errorListener(e: any) {
        console.log(e);
        // callback to occur on error
        log.error(
          `Error: code: ${e.code} name: ${e.name} title: ${e.title}`,
          e.message
        );
      },
    };

    const initContent = {
      ...generateInitConfig(isIntractivesEnabled),
      ...content,
      rich_text_editor: {
        customButtons: [
          {
            name: 'customVideoButton',
            label: 'Upload video',
            icon: 'https://www.youtube.com/s/desktop/b00eb1fd/img/favicon_48x48.png',
            func: async (attribute, callback) => {
              // const response: any = await this.query(
              //   {
              //     query: QueryGetTableOfContentId,
              //     variables: {
              //       productId: 'f9ab7095-c60b-494b-9e3c-58f97b4f86de',
              //     },
              //   },
              //   (error) => {
              //     const occurredError = error?.graphQLErrors
              //       ? error?.graphQLErrors[0]
              //       : null;

              //     try {
              //       const { message } = occurredError;
              //       Toast.error(
              //         `Failed to load (${
              //           message || 'Internal server error'
              //         }). Report the error to your project contact.`
              //       );
              //     } catch (err) {
              //       Toast.error(
              //         'Failed to load (Internal server error). Report the error to your project contact.'
              //       );
              //       console.log('Error message is not found', err);
              //     }
              //   }
              // );
              // console.log('response', response);

              const tocId = 'cea1f5f5-15b3-4f6b-8882-4cf364e5625f';

              console.log('tocId', tocId);
              console.log('projectId', 'f9ab7095-c60b-494b-9e3c-58f97b4f86de');

              Framework.sendEvent(constants.HTML_POST_MESSAGE, {
                projectId: 'f9ab7095-c60b-494b-9e3c-58f97b4f86de',
                tocId,
                assetType: 'Image',
              });

              try {
                console.log('events');
                window.addEventListener(
                  'action',
                  (event) => {
                    console.log('action', event);
                  },
                  false
                );
                window.addEventListener(
                  'Action',
                  (event) => {
                    console.log('Action', event);
                  },
                  false
                );
                window.addEventListener(
                  'MessageEvent',
                  (event) => {
                    console.log('MessageEvent', event);
                  },
                  false
                );
                window.addEventListener(
                  'message',
                  (event) => {
                    console.log('message', event);
                  },
                  false
                );
                window.addEventListener(
                  'message',
                  (event) => {
                    console.log('message', event);
                  },
                  false
                );
              } catch (error) {
                console.log('Event listener error', error);
              }

              callback('');
            },
          },
        ],
      },
    };

    questionEditorApp = LearnosityQuestionEditor.init(
      initContent,
      `.${className}`,
      callbacks
    );
  }
}
