const restyleCheckAnswerButton = (
  questionResponseId: string,
  buttonClass: string
) => {
  const checkAnswerButton = document
    .getElementById(`${questionResponseId}`)
    .querySelector('.lrn_btn.lrn_validate') as HTMLButtonElement;

  if (checkAnswerButton) {
    if (buttonClass !== '') {
      checkAnswerButton.classList.add(buttonClass);
      checkAnswerButton.setAttribute('disabled', '');
    } else {
      checkAnswerButton.classList.remove('disabled');
      checkAnswerButton.removeAttribute('disabled');
    }
  }
};

export default restyleCheckAnswerButton;
