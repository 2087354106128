import gql from 'graphql-tag';

export default gql`
  mutation createContentByProductId(
    $content: AWSJSON!
    $fileName: String!
    $productId: String!
  ) {
    createContentByProductId(
      newContent: {
        content: $content
        fileName: $fileName
        productId: $productId
      }
    ) {
      cmsId
      content
      fileName
    }
  }
`;
