const flashcardFunction = () => {
  const handleFlashcardTileClick = () => {
    setTimeout(() => {
      const flashcardTile = document.getElementsByClassName(
        'lrn-qe-a lrn-qe-tile-preview'
      )[0];
      if (
        flashcardTile &&
        flashcardTile.getAttribute('data-lrn-qe-template-reference') ===
          'customFlashcards'
      ) {
        flashcardTile.setAttribute(
          'href',
          `${window.location.origin}/flashcards/create`
        );
        flashcardTile.setAttribute('onclick', 'event.stopPropagation()');
      }
    }, 500);
  };
  const flashcardGroup = document.getElementById('grRef_flashcards');
  if (flashcardGroup) {
    flashcardGroup.setAttribute(
      'href',
      `${window.location.origin}/Interactives`
    );
    flashcardGroup.onclick = handleFlashcardTileClick;
  }
};

export default flashcardFunction;
