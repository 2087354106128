interface Handlers {
  onSave: () => void;
}
const applySaveButton = ({ onSave }: Handlers) => {
  const ul = document.querySelector('.lrn-qe-toolbar-controls');

  if (ul) {
    const button = document.createElement('button');
    button.setAttribute('id', 'save');
    button.value = 'Save';
    button.classList.add('btn-source');
    button.setAttribute('data-lrn-qe-global-help', '');
    button.classList.add('lrn-qe-toolbar-button');
    button.classList.add('lrn-qe-inline-block');
    button.classList.add('lrn-qe-viewstack-item');
    button.appendChild(document.createTextNode('Save'));
    button.addEventListener('click', onSave);
    ul.appendChild(button);
  }
};

export default applySaveButton;
