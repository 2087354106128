import QuestionEditor from './QuestionEditor';
import * as constants from '../../../../../common/constants';

export default class Edit extends QuestionEditor {
  constructor() {
    super(constants.QUESTION_EDIT_SERVICE);
  }

  async handleEvent(_: any, event: any) {
    super.load(`edit_${event.id}`, {
      ui: { change_button: false },
      widget_json:
        typeof event.content === 'string'
          ? JSON.parse(event.content)
          : event.content,
    });
  }
}
