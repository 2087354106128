import { Framework } from '@wiley-flint/framework';
import { applySnapshot, types } from 'mobx-state-tree';
import * as constants from '../../../../common/constants';
import applySaveButton from '../applySaveButton';
import ImageStore from './Image';
import questionMapper from '../mappers/questionMapper';
import contentMapper from '../mappers/contentMapper';
import applyQuestionFileName from '../applyQuestionFileName';
import applyCustomFlashCard from '../applyCustomFlashCard';
import { getParameterByPath } from '../../../../common/utils/RouterPathUtility';

const parameter = getParameterByPath();
const questionModel = {
  cmsId: types.maybeNull(types.string),
  fileName: types.maybeNull(types.string),
  content: types.maybeNull(types.frozen<any>()),
  projectId: types.maybeNull(types.string),
  contentMapId: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
  selectedImage: types.optional(ImageStore, () =>
    ImageStore.create({
      editing: false,
      uploading: false,
      metadata: {
        url: null,
      },
    })
  ),
};
questionModel[`${parameter}`] = types.maybeNull(types.string);

const Question = types.model('question', questionModel).actions((self) => ({
  fetch({ cmsId }: any) {
    console.log(`Sending a event to FLINT with CMSID Argument: ${cmsId}`);
    Framework.sendEvent(constants.QUESTION_VIEW_REQUEST, {
      cmsId,
    });
  },
  create(id: string, refId: string) {
    const applySnapShotModel = { ...self };
    applySnapShotModel[`${parameter}`] = refId;
    applySnapshot(self, { ...applySnapShotModel });
    Framework.sendEvent(constants.QUESTION_CREATE_SERVICE, { id });
  },
  view({ cmsId }: any) {
    Framework.sendEvent(constants.QUESTION_VIEW_REQUEST, {
      cmsId,
      followedByService: constants.QUESTION_PREVIEW_SERVICE,
    });
  },
  edit({ cmsId }: any) {
    Framework.sendEvent(constants.QUESTION_VIEW_REQUEST, {
      cmsId,
      followedByService: constants.QUESTION_EDIT_SERVICE,
    });
  },
  setFileName(fileName: string) {
    applySnapshot(self, { ...self, fileName });
  },
  loaded(eventName: string) {
    applyCustomFlashCard();
    if (eventName === constants.QUESTION_CREATE_SERVICE) {
      applySaveButton({
        onSave: () => {
          if (self.fileName) {
            const model = {
              content: self.content,
              cmsId: self.cmsId,
              fileName: self.fileName,
            };
            model[`${parameter}`] = self[`${parameter}`];
            Framework.sendEvent(constants.QUESTION_CREATE_REQUEST, {
              ...model,
            });
          } else {
            applyQuestionFileName({
              onValueChange: (value: any) => {
                this.setFileName(value);
              },
              error: {
                status: true,
                message: 'False',
              },
            });
          }
        },
      });
      applyQuestionFileName({
        onValueChange: (value: any) => {
          this.setFileName(value);
        },
        error: {
          status: false,
          message: '',
        },
      });
    } else if (eventName === constants.QUESTION_EDIT_SERVICE) {
      applySaveButton({
        onSave: () => {
          Framework.sendEvent(constants.QUESTION_UPDATE_REQUEST, {
            cmsId: self.cmsId,
            content: self.content,
            fileName: self.fileName,
          });
        },
      });
      applyQuestionFileName({
        onValueChange: (value: any) => {
          this.setFileName(value);
        },
        error: {
          status: false,
          message: '',
        },
        questionFileName: self.fileName,
      });
    }
  },
  update: (question: Question) => {
    applySnapshot(self, { ...self, ...questionMapper(question) });
  },
  updateContent: (content: any) => {
    self.content = { ...self.content, ...contentMapper(content) };
  },
  forceUpdate: () => {
    Framework.sendEvent(constants.QUESTION_UPDATE_REQUEST, {
      cmsId: self.cmsId,
      content: self.content,
      fileName: self.fileName,
    });
  },
  set(response: any) {
    if (!response) return;
    applySnapshot(
      self,
      questionMapper({ ...response, content: JSON.parse(response.content) })
    );
    if (self.content?.image?.src) {
      self.selectedImage.setUrl(self.content?.image?.src);
    }
  },
}));

export default Question;
