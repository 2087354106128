import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import DeleteIcon from '@mui/icons-material/Delete';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import CardField from './cardComponents/CardField';

interface CardOnSaveArgs {
  id: number;
  actualId: string;
  term: string;
  frontType: string;
  definition: string;
  backType: string;
}
interface AuthoringCardProps {
  index: number;
  id: number;
  save: boolean;
  termText?: string;
  frontSelectType?: string;
  definitionText?: string;
  backSelectType?: string;
  prevActiveCardId?: number;
  actualId?: string;
  onDataChange?: (id: number, data: any) => void;
  onCardDelete: (cardId: number) => void;
  onCardActive?: (id: number) => void;
  onCardSave?: (CardOnSaveArgs: CardOnSaveArgs) => void;
}

const AuthoringCard: React.FunctionComponent<AuthoringCardProps> = ({
  index,
  id,
  save,
  termText,
  frontSelectType,
  definitionText,
  backSelectType,
  prevActiveCardId,
  actualId,
  onDataChange,
  onCardDelete,
  onCardActive,
  onCardSave,
}) => {
  const [cardId, setCardId] = useState(id);
  const [actualCardId, setActualCardId] = useState('');
  const [term, setTerm] = useState('');
  const [frontType, setFrontType] = useState('Text');
  const [definition, setDefinition] = useState('');
  const [backType, setBackType] = useState('Text');
  const [errorStatus, setErrorStatus] = useState(false);

  const handleOnCardClick = () => {
    onCardActive(id);
    setErrorStatus(false);
  };

  const handleFrontTypeChange = (typeValue: string) => {
    setFrontType(typeValue);
  };

  const handleTermChange = (textValue: string) => {
    setTerm(textValue);
  };

  const handleBackTypeChange = (typeValue: string) => {
    setBackType(typeValue);
  };

  const handleDefinitionChange = (textValue: string) => {
    setDefinition(textValue);
  };

  useEffect(() => {
    if (id) {
      setCardId(id);
    }
  }, [id]);

  useEffect(() => {
    if (actualCardId) {
      setActualCardId(actualCardId);
    }
  }, [actualCardId]);

  useEffect(() => {
    if (termText) {
      setTerm(termText);
    }
  }, [termText]);

  useEffect(() => {
    if (definitionText) {
      setDefinition(definitionText);
    }
  }, [definitionText]);

  useEffect(() => {
    if (frontSelectType) {
      setFrontType(frontSelectType);
    }
  }, [frontSelectType]);

  useEffect(() => {
    if (backSelectType) {
      setBackType(backSelectType);
    }
  }, [backSelectType]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      const cardData = {
        cardId,
        term,
        actualId,
        frontType,
        definition,
        backType,
      };
      onDataChange(cardId, cardData);
    }, 1000);
    return () => clearTimeout(timeout);
  }, [cardId, term, actualId, frontType, definition, backType]);

  const handleSwap = () => {
    setFrontType(backType);
    setTerm(definition);
    setBackType(frontType);
    setDefinition(term);
  };

  const handleDelete = () => {
    onCardDelete(cardId);
  };

  const handleClickAway = () => {
    if (cardId === prevActiveCardId && !(term === '' && definition === '')) {
      setErrorStatus(true);
    }
    if (cardId === prevActiveCardId && term.trim() && definition.trim()) {
      const onSaveArguments = {
        id: cardId,
        actualId,
        term,
        frontType,
        definition,
        backType,
      };
      onCardSave(!save && onSaveArguments);
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Card sx={{ maxWidth: 600 }} onClick={handleOnCardClick}>
        <CardContent>
          <Typography gutterBottom variant="h6" component="div">
            Card {index} Front
          </Typography>
          <CardField
            typeValue={frontType}
            onTypeChange={handleFrontTypeChange}
            textValue={term}
            onTextChange={handleTermChange}
            errorStatus={errorStatus}
          />
          <Typography gutterBottom variant="h6" component="div">
            Card {index} Back
          </Typography>
          <CardField
            typeValue={backType}
            onTypeChange={handleBackTypeChange}
            textValue={definition}
            onTextChange={handleDefinitionChange}
            errorStatus={errorStatus}
          />
          <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
            <Tooltip title="Swap sides" arrow placement="right">
              <IconButton aria-label="swap" onClick={handleSwap}>
                <SwapVertIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete card" arrow placement="left">
              <IconButton aria-label="delete" onClick={handleDelete}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        </CardContent>
      </Card>
    </ClickAwayListener>
  );
};

export default AuthoringCard;
