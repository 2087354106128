import Assessments from './models/assessments';

const assessmentsStore = {
  name: 'Assessments',
  spec: Assessments,
  saveLocally: false,
  initialState: {
    searchWasDone: false,
    term: '',
    cities: [],
  },
};

export default [assessmentsStore];
